import React from 'react'

const GoogleTagLinkCreator = ({ link, img, socialLinks, name }) => {
    console.log(socialLinks, 'social bhaiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii');
    return (
        <a id={name} className="btn-square" href={link} onclick={`fbq('trackCustom', '${name} click',{click_type:'${name}_click'});gtag('event', '${name} click');`} target='_blank'><img src={img} alt={`Yarley G's ${name}`} /></a>
    )
}

export default GoogleTagLinkCreator
