import React, { useMemo, useRef, useState, useEffect } from "react";
import { useHideOnOutsideDetect } from "../hooks/useHideOnOutsideDetect";

// const TypeAhead = ({ data, state, setState, attrValue, label, suggestionDivId, ariaLabel}) => {
//   const [allData, setallData] = useState([]);
//   const [filteredData, setfilteredData] = useState([]);

//   const divToHide = useRef(null);
//   OnOutsideDetect(divToHide);

//   const changeHandler = (text) => {
//     const suggestionDiv = window.document.getElementById(suggestionDivId);
//     setState({...state, name:text});
//     const dataa = data;

//     suggestionDiv.style.display = "block";
//     // if (text) {
//     // } else {
//     //   suggestionDiv.style.display = "none";
//     // }

//     if (text) {
//       const found = dataa.filter((e) => e.toLowerCase().includes(text.toLowerCase()));
//       setfilteredData(found.length > 0 ? found : ['No Match Found']);
//     } else {
//       setfilteredData(allData);
//     }
//   };

//   const clickHandler = (e) => {
//     setState({...state, name: e.target.innerText});
//     document.getElementById(suggestionDivId).style.display = "none";
//   };

//   useMemo(() => {
//     setallData(data);
//     setfilteredData(data);
//   }, []); 



//   return (
//     <>
//       <div className="form-floating">
//         <input
//           type="text"
//           className="form-control text-capitalize"
//           id={attrValue}
//           name={attrValue}
//           aria-label={ariaLabel}
//           value={state.name}
//           onFocus={() => {
//             const suggestionDiv =
//               window.document.getElementById(suggestionDivId);
//             suggestionDiv.style.display = "block";
//           }}
//           onChange={(e) => changeHandler(e.target.value)}
//           placeholder={label}
//           required
//         />
//         <label for={attrValue}>{label}</label>

//         <div
//         ref={divToHide}
//           id={suggestionDivId}
//           style={{
//             display: "none",
//             position: "absolute",
//             backgroundColor: "white",
//             border: "1px solid black",
//             zIndex: 9999,
//             width: "100%",
//             borderRadius: "5px",
//             padding: "5px",
//             maxHeight: "200px",
//             overflowY: "auto",
//             overflowX: "hidden",
//           }}
//         >
//           {filteredData.map((names, i) => {
//             return (
//               <button
//                 type="button"
//                 className="d-block p-2 btn w-100 text-start"
//                 key={i}
//                 disabled={names == 'No Match Found'}
//                 onClick={(e) => clickHandler(e)}
//               >
//                 {names}
//               </button>
//             );
//           })}
//         </div>
//       </div>
//     </>
//   );
// };



// const TypeAhead = ({ list, state, setState, attrValue, label, suggestionDivId, ariaLabel });



const TypeAhead = ({ list, keyProp, state, setState, attrValue, label, ariaLabel, suggestionDivId }) => {
  const [inputValue, setInputValue] = useState(state.name);
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    setFilteredNames(list);
  }, []);

  useEffect(() => {
    setInputValue(state.name);
  }, [state.name]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setState((prevState) => ({ ...prevState, [keyProp]: value }));

    // Filter names based on user input
    const filtered = list.filter((name) => {
      return name?.toLowerCase().includes((value?.toLowerCase()).trim())
    });

    setFilteredNames(filtered);
    setDropdownVisible(filtered.length > 0);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        navigateDropdown(1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        navigateDropdown(-1);
        break;
      case 'Enter':
        e.preventDefault()
        if (filteredNames.length === 0) {
          // Handle case when there are no filtered names
        } else if (selectedName) {
          handleNameSelect(selectedName);
        } else if (filteredNames.length > 0) {
          handleNameSelect(filteredNames[0]);
        }
        break;
      case 'Escape':
        if (inputValue === '' && selectedName) {
          setInputValue(selectedName);
        }
        setDropdownVisible(false);
        break;
      default:
        break;
    }
  };

  const handleNameSelect = (name) => {
    setSelectedName(name);
    setInputValue(name);
    setDropdownVisible(false);
    inputRef.current.focus();
    setState((prevState) => ({ ...prevState, [keyProp]: name }));
  };

  const navigateDropdown = (step) => {
    const currentIndex = filteredNames.indexOf(selectedName);
    let newIndex = currentIndex + step;

    if (newIndex < 0) {
      newIndex = filteredNames.length - 1;
    } else if (newIndex >= filteredNames.length) {
      newIndex = 0;
    }

    setSelectedName(filteredNames[newIndex]);
  };

  const handleInputBlur = () => {
    // Use setTimeout to wait for the potential click on the dropdown
    setTimeout(() => {
      setDropdownVisible(false);
    }, 200);
  };

  return (
    <div className="dropdown dropdown-musto-typeahead">
      <div className="form-floating">
        <input
          id={attrValue}
          name={attrValue}
          type="text"
          value={inputValue}
          required
          onFocus={handleInputChange}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          placeholder={label}
          ref={inputRef}
          className="form-control"
          aria-label={ariaLabel}
          aria-haspopup="listbox"
          aria-expanded={isDropdownVisible}
          role="combobox"
          aria-autocomplete="list"
          aria-owns={suggestionDivId}
          aria-activedescendant={selectedName}
          data-toggle="dropdown"
        />
        <label for={attrValue}>{label}</label>
      </div>

      {isDropdownVisible && (
        <ul
          id={suggestionDivId}
          role="listbox"
          aria-labelledby="typeahead-label"
          className="dropdown-menu show"
        >
          {console.log(filteredNames, 'bhai pleasee')}
          {filteredNames.map((name, index) => (
            <li
              key={index}
              role="option"
              aria-selected={name === selectedName}
              id={name}
              className={`dropdown-item ${name === selectedName ? 'active' : ''}`}
            >
              <button
                type="button"
                className="w-100"
                onClick={() => handleNameSelect(name)}
              >
                {name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};


export default TypeAhead;