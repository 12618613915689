import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import YARLEY from "../assets/yarleyg.png"

import YOUTUBE from "../assets/svg/youtube.svg"
import SPOTIFY from "../assets/svg/spotify.svg"
import ITUNES from "../assets/svg/itunes.svg"
import TIDAL from "../assets/svg/tidal.svg"
import AMAZON_MUSIC from "../assets/svg/amazon-music.svg"
import SOUND_CLOUD from "../assets/svg/soundcloud.svg"
import APPLE_PODCAST from "../assets/svg/apple-podcast.svg"
import { GetSongData, GetVideosOfSong } from '../ContextAPI/APIs/api'
import { FILE_URL, ARTIST } from '../config'
import { convertToTitleCase } from '../hooks/removeDashAddSpace'
import VideoModal from '../components/VideoModal'
import Loader from '../components/dashboard-components/loader'




function Song() {
    const navigate = useNavigate();

    const [ShowMenu, setShowMenu] = useState(false)

    const { id } = useParams()
    const [song, setSong] = useState(null)
    const [videos, setVideos] = useState([])

    const getSongData = async () => {
        const res = await GetSongData(id, { type: ARTIST })
        if (res.success && res.message != null) {
            setSong(res.message)
        } else {
            navigate('/')
        }
    }

    const getVideos = async () => {
        const res = await GetVideosOfSong(id)
        if (res.success && res.data != null) {
            setVideos(res.data)
        }
    }

    const FilterForPrimary = (array) => {
        const primaryArray = array.filter(e => e.type == "primary")
        return primaryArray
    }

    const filterForFeatured = (array) => {
        const featuredArray = array.filter(e => e.type == "featured-artist")
        return featuredArray
    }

    const filterLyricType = (id) => {
        let count = 0;
        const verseArray = song.lyrics.filter(e => e.type == "verse")

        verseArray.forEach((element, i) => {
            if (element.id == id) count = i;
        });

        return count + 1
    }


    useEffect(() => {
        if (ARTIST == "") return navigate('/')

        window.scrollTo(0, 0);
        getSongData()
        getVideos()

    }, [])



    if (song == null) return <Loader />


    return (
        <>
            <div className="skippy visually-hidden-focusable overflow-hidden">
                <div className="container">
                    <a className="d-inline-flex p-2 m-1" href="#releases">Skip to releases</a>
                </div>
            </div>

            <main>

                {/* <!-- Back Btn --> */}
                <div className="bg-dark p-3 py-lg-4 text-center sticky-top back-btn" data-aos="fade-down" data-aos-offset="20" data-aos-delay="100" data-bs-theme="dark">
                    <button className="btn btn-outline-light song-menu" onClick={() => navigate(-1)}>Go back</button>
                </div>

                {/* <!-- Project Icon --> */}
                <section>
                    <div className="py-md-5 py-4 bg-white text-black text-center">
                        <div className="container">
                            <div className="row">
                                <div className="d-grid gap-5">
                                    <div>
                                        <h1 className="" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">{song.title}</h1>
                                        <div className="fw-normal h3" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                            {
                                                typeof song.artists !== "undefined" && song.artists.length > 0 && FilterForPrimary(song.artists)?.map((artist, i) => {
                                                    if (artist?.type === "primary") {
                                                        return (
                                                            <span key={i} className='text-capitalize' >
                                                                {artist.name}
                                                                {i < (FilterForPrimary(song.artists))?.length - 1 && ','} {/* Add comma if not the last artist */}
                                                            </span>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }

                                            <br />
                                            featuring &nbsp;

                                            {
                                                typeof song.artists !== "undefined" && song.artists.length > 0 && filterForFeatured(song.artists)?.map((artist, i) => {
                                                    if (artist?.type === "featured-artist") {
                                                        return (
                                                            <span key={i} className='text-capitalize'>
                                                                {artist.name}
                                                                {i < filterForFeatured(song.artists)?.length - 1 && ','} {/* Add comma if not the last artist */}
                                                            </span>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="music-icon">
                                        <img src={`${FILE_URL}/${song.img}`} alt="" data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = YARLEY;
                                            }}
                                        />
                                    </div>
                                    <ul data-aos="zoom-in-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20"
                                        className="d-flex flex-wrap gap-3 list-unstyled mx-auto justify-content-center social-group shadow p-2 rounded">
                                        <span className="visually-hidden">{song.title}</span>
                                        {(song.youtube != "" && song.youtube != null) && <li><a className="btn-square" href={song.youtube} target='_blank'><img src={YOUTUBE} alt="Yarley G's Youtube" /></a></li>}
                                        {(song.spotify != "" && song.spotify != null) && <li><a className="btn-square" href={song.spotify} target='_blank'><img src={SPOTIFY} alt="Yarley G's Spotify" /></a></li>}
                                        {(song.appleMusic != "" && song.appleMusic != null) && <li><a className="btn-square" href={song.appleMusic} target='_blank'><img src={ITUNES} alt="Yarley G's Apple Music" /></a></li>}
                                        {(song.tidal != "" && song.tidal != null) && <li><a className="btn-square" href={song.tidal} target='_blank'><img src={TIDAL} alt="Yarley G's Tidal" /></a></li>}
                                        {(song.amazonMusic != "" && song.amazonMusic != null) && <li><a className="btn-square" href={song.amazonMusic} target='_blank'><img src={AMAZON_MUSIC} alt="Yarley G's Amazon Music" /></a></li>}
                                        {(song.soundCloud != "" && song.soundCloud != null) && <li><a className="btn-square" href={song.soundCloud} target='_blank'><img src={SOUND_CLOUD} alt="Yarley G's Soundcloud" /></a></li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Releases --> */}
                <section className="releases">
                    <div className="bg-dark text-white back-btn-space" data-bs-theme="dark">
                        {/* <!-- Open menu with fade --> */}
                        <div className="sticky-top">
                            <div className="container">
                                <div className="d-lg-none pt-3 pb-3 bg-dark">
                                    <a onClick={() => { setShowMenu(true) }} className="btn btn-outline-light w-100 toggle-menu">
                                        Open Credits and Lyrics Menu
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3">
                                    <div className={`side-menu sticky-lg-top w-100 scroll-fade top-lg-6rem ${ShowMenu && 'show'}`}>
                                        {/* <!-- Close menu with fade --> */}
                                        <div className="d-lg-none mb-4">
                                            <a className="btn btn-outline-light w-100 toggle-menu" onClick={() => { setShowMenu(false) }}>
                                                Close Credits and Lyrics Menu
                                            </a>
                                        </div>
                                        <div className="side-scrolling pt-lg-4rem skip-back-btn">
                                            <div className="accordion d-grid gap-lg-5 gap-4 px-3 pt-1 pb-5">
                                                {
                                                    (song.credits).length > 0 &&
                                                    <div className="accordion-item">
                                                        <h3 className="accordion-header">
                                                            <button className="accordion-button lh-1" type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#accordion1" aria-expanded="true"
                                                                aria-controls="accordion1">
                                                                Credits
                                                            </button>
                                                        </h3>
                                                        <div id="accordion1" className="accordion-collapse collapse show">
                                                            <div className="accordion-body py-3">
                                                                <div className="px-3">
                                                                    {
                                                                        (song.credits).map((credit, i) => {
                                                                            return <dl key={i}>
                                                                                <dt>{credit.type && convertToTitleCase(credit.type)}</dt>
                                                                                <dd>{credit.name}</dd>
                                                                            </dl>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (song.lyrics).length > 0 &&
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button lh-1" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#accordion2"
                                                                aria-expanded="false" aria-controls="accordion2">
                                                                Lyrics
                                                            </button>
                                                        </h2>
                                                        <div id="accordion2" className="accordion-collapse collapse show">
                                                            <div className="accordion-body py-3">
                                                                <div className=" px-3">
                                                                    {
                                                                        (song.lyrics).map((lyric, i) => {
                                                                            return <dl key={i}>
                                                                                {
                                                                                    lyric.type == "chorus or hook" ?
                                                                                        <dt>[Chorus or Hook]</dt>
                                                                                        :
                                                                                        <dt className='text-capitalize'>[{lyric.type}{lyric.type == "verse" && `\u00A0${filterLyricType(lyric.id)}`}]</dt>
                                                                                }
                                                                                <dd>{lyric.lyric}</dd>
                                                                            </dl>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg text-center scroll-fade skip-back-btn">
                                    <div className="d-grid gap-5 py-5">
                                        {/* <!-- Rise --> */}
                                        <article id="rise" className="d-grid pt-4">
                                            <div className="row g-3">
                                                {
                                                    videos.length > 0 && videos.map((video, i) => {
                                                        return <div key={i} className="col-md-6 flex-grow-1">
                                                            <div className="video-item d-grid gap-3" data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20" style={{ backgroundImage: `url(${FILE_URL}/${video?.img})` }}>
                                                                <div className="video-detail">
                                                                    <div>
                                                                        <VideoModal videoId={video.videoYoutube} videoTitle={video.videoType || 'youtube video'} />
                                                                    </div>
                                                                    <div className="video-description d-grid gap-3">
                                                                        <h4 className="h2">{video.videoType}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                ((
                                                                    (video?.podcastYoutube != "" && video?.podcastYoutube != null && typeof video?.podcastYoutube != "undefined") ||
                                                                    (video?.podcastSpotify != "" && video?.podcastSpotify != null && typeof video?.podcastSpotify != "undefined") ||
                                                                    (video?.podcastItunes != "" && video?.podcastItunes != null && typeof video?.podcastItunes != "undefined") ||
                                                                    (video?.podcastAmazonMusic != "" && video?.podcastAmazonMusic != null && typeof video?.podcastAmazonMusic != "undefined")
                                                                )
                                                                    && (video.podcastToggle == true))
                                                                &&
                                                                <ul style={{ marginTop: "20px" }} className="d-flex gap-3 w-100 list-unstyled mx-auto justify-content-center social-group shadow p-2 rounded">
                                                                    <span className="visually-hidden">I Love You Lord 'Breaking Down The Beat' Videos on other platforms</span>
                                                                    {
                                                                        (video?.podcastYoutube != "" && video?.podcastYoutube != null && typeof video?.podcastYoutube != "undefined") &&
                                                                        <li>
                                                                            <a className="btn-square" href={video?.podcastYoutube}>
                                                                                <img src={YOUTUBE} alt={"Podcast Youtube"} />
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (video?.podcastSpotify != "" && video?.podcastSpotify != null && typeof video?.podcastSpotify != "undefined") &&
                                                                        <li>
                                                                            <a className="btn-square" href={video?.podcastSpotify}>
                                                                                <img src={SPOTIFY} alt={"Podcast Spotify"} />
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (video?.podcastItunes != "" && video?.podcastItunes != null && typeof video?.podcastItunes != "undefined") &&
                                                                        <li>
                                                                            <a className="btn-square" href={video?.podcastItunes}>
                                                                                <img src={APPLE_PODCAST} alt={"Podcast Itunes"} />
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (video?.podcastAmazonMusic != "" && video?.podcastAmazonMusic != null && typeof video?.podcastAmazonMusic != "undefined") &&
                                                                        <li>
                                                                            <a className="btn-square" href={video?.podcastAmazonMusic}>
                                                                                <img src={AMAZON_MUSIC} alt={"Podcast AmazonMusic"} />
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}


export default Song