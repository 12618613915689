import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import youtubeIcon from '../../assets/svg/Streaming.svg'
import photo from '../../assets/svg/photo.svg'
import { getPV, updatePV } from '../../ContextAPI/APIs/api'
import { useToast } from '../../ContextAPI/Components/notify'
import ImageComp from '../../components/dashboard-components/ImageComp'
import { useLoader } from '../../ContextAPI/Components/loaderContext'
import { useAuth } from '../../ContextAPI/Components/auth'




const PromotionalVideo = () => {

    const { toastAlert } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const [title, settitle] = useState("")
    const [author, setauthor] = useState("")
    const [youtube, setyoutube] = useState("")
    const [thumbnailphoto, setthumbnailphoto] = useState("")
    const [fileName, setFileName] = useState(null)
    const { user, getLoginUser } = useAuth();


    const getData = async () => {
        showLoader()

        let thisUser = user;
        if (thisUser == null) thisUser = await getLoginUser()


        const res = await getPV({ type: thisUser.username });
        const data = res.message;

        if (!data) { }
        else {
            settitle(data.title);
            setauthor(data.author);
            setyoutube(data.link)
            setFileName({ name: data.img })
            setthumbnailphoto({ name: data.img })

        }
        hideLoader()
    }

    const submit = async (e) => {
        e.preventDefault();
        showLoader()

        console.log("youtube", youtube);

        const myData = new FormData();
        myData.append('title', title || null);
        myData.append('author', author || null);
        myData.append('link', youtube || null);
        myData.append('img', fileName?.name || "");
        myData.append('myfile', thumbnailphoto);

        const res = await updatePV(myData);

        toastAlert(res.message, res.success ? "success" : "error")
        await getData()
        hideLoader()
    }

    useEffect(() => {
        getData();

    }, [])


    return <>
        <section>

            <DashHead />

            <div className="container-admin">


                <div className="row mb-5 ">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2>Promotional Video</h2>
                        <form className="d-grid gap-3 my-3" action="">
                            <div className="form-floating">
                                <input type="text" className=' form-control' name="title" id="title" value={title} onChange={(E) => settitle(E.target.value)} placeholder='Title' required />
                                <label htmlFor="text">Title</label>
                            </div>

                            <div className="form-floating">
                                <input type="text" className=' form-control' name="author" id="author" value={author} onChange={(E) => setauthor(E.target.value)} placeholder='Author' required />
                                <label htmlFor="text">Author</label>
                            </div>
                            <div className={`form-floating form-social social-icon-youtube`}>
                                <input type="text" class="form-control" id='youtube' value={youtube} onChange={(E) => setyoutube(E.target.value)} placeholder='Youtube Link' required />
                                <label for='youtube'>Youtube Link</label>
                            </div>

                            <div class="input-group overflow-hidden">
                                <input type="file" className="form-control" id="profilePhoto" onChange={(e) => setthumbnailphoto(e.target.files[0])} required />

                                <ImageComp myfile={thumbnailphoto} setmyfile={setthumbnailphoto} setFileName={setFileName} forr={'profilePhoto'} text={'Upload video thumbnail photo'} />

                            </div>

                            <button onClick={(e) => submit(e)} className='btn btn-primary '>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}


export default PromotionalVideo