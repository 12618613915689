import React from 'react'
import Sidebar from './dashboard-components/Sidebar'
import DashHead from './dashboard-components/dashHead'

const LayoutDash = ({ children }) => {
    return <>

        <section>
            <DashHead />

            <div className="container-admin">
                <div className="row mb-5 ">
                    <div className="col-4 position-relative">
                        <Sidebar />
                    </div>

                    <div className="col-lg">
                        {children}
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default LayoutDash
