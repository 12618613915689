import { CombineComponents } from "./CombineContext";
import { AuthProvider } from "./Components/auth";
import { LoaderProvider } from "./Components/loaderContext";
import { ToastProvider } from "./Components/notify";
import { SidebarProvider } from "./Components/sidebar";
import { CreditorsArtistProvider } from "./Components/CreditorsArstisState";
import { SEOProvider } from "./Components/seo";

const providers = [
    ToastProvider,
    AuthProvider,
    LoaderProvider,
    SidebarProvider,
    CreditorsArtistProvider,
    SEOProvider
]

export const AppContextProvider = CombineComponents(...providers)