import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';



// export const GTM_CONTAINER_ID = '';
// const GA_TRACKING_ID = 'G-87Z9F447XC';

// export const GTM_CONTAINER_ID = 'GTM-WXD9MZFQ';
// export const GA_TRACKING_ID = 'G-MLS0NBFNWG';


export const initGTM = (GTM_CONTAINER_ID) => {
    TagManager.initialize({ gtmId: GTM_CONTAINER_ID });
};

export const initGA = (GA_TRACKING_ID) => {
    ReactGA.initialize(GA_TRACKING_ID);
};

export const initFBPixel = (FB_PIXEL_ID) => {
    ReactPixel.init(FB_PIXEL_ID); // Initialize Facebook Pixel with your Pixel ID
    ReactPixel.pageView(); // For tracking page views
    console.warn("PIXEL PIXEL PIXEL")
}

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const logEvent = (category, action, label, value) => {
    ReactGA.event({
        category,
        action,
        label,
        value,
    });
};


export const handleFireEvent = (e, name) => {

    console.warn(name)

    // Handle button click logic here
    // Optionally, you can track an event using GTM
    TagManager.dataLayer({
        dataLayer: {
            event: name,
            eventCategory: 'Click', // Optional category for better organization
            eventAction: name, // The button name or identifier
            event_label: name, // The button name or identifier
        },
    });

    logEvent('User Interaction', name, name, 1);

    ReactPixel.trackCustom('trackCustom', {
        content_name: name,
        content_type: name,
    });

};