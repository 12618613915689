import axios from "axios";
import { API_URL } from "../../config";
import { createUrl } from "../../helpers/createUrl";




const signup = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/sign-up`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        console.log("data", data);
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const signin = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/sign-in`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const completeProfile = async (body, id) => {
    try {
        let url = "";
        if (typeof id != "undefined" && id != null && id != "") url = `${API_URL}/user/complete-profile/${id}`
        else url = `${API_URL}/user/complete-profile`

        const data = await axios.post(url, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const forgotPassword = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/forget-password`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const updateEmail = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/update-email`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const updatePassword = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/update-password`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const loginUser = async () => {
    try {
        const data = await axios.get(`${API_URL}/user/get-login-user`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getSocialLinks = async (params) => {
    try {
        const data = await axios.get(`${API_URL}/sociallinks/get${createUrl(params)}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const updateSocialLinks = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/sociallinks/update`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getMailChimp = async (params) => {
    try {
        const data = await axios.get(`${API_URL}/mailchimp/get${createUrl(params)}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}



const updateMailChimp = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/mailchimp/update`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getPCA = async (params) => {
    try {
        const data = await axios.get(`${API_URL}/pc/get${createUrl(params)}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}



const updatePCA = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/pc/update`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}

const getPV = async (params) => {
    try {
        const data = await axios.get(`${API_URL}/pv/get${createUrl(params)}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}



const updatePV = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/pv/update`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getLayout = async (params) => {
    try {
        const data = await axios.get(`${API_URL}/layout/get-layout${createUrl(params)}`)

            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const updateLayout = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/layout/update-layout`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const updateLayoutImg = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/layout/update-layout-img`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const createAlbum = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/album/create`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


// Dashboard only
const getAllAlbumsName = async () => {
    try {
        const data = await axios.get(`${API_URL}/album/get-albums-name`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getAllAlbum = async () => {
    try {
        const data = await axios.get(`${API_URL}/album/get`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const DeleteAlbum = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/album/delete`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getAllAlbumHomepage = async (params) => {
    try {
        const data = await axios.get(`${API_URL}/album/get-all-homepage${createUrl(params)}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

                // return ({ success: true });

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const LinkSongWithAlbum = async (id, body) => {
    try {
        const data = await axios.post(`${API_URL}/album/link-songs-with-album/${id}`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetAllSongs = async () => {
    try {
        const data = await axios.get(`${API_URL}/songs/get`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetSongData = async (id, params) => {
    try {
        const data = await axios.get(`${API_URL}/songs/get/${id}${createUrl(params)}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const createSong = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/create`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const DeleteSong = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/delete`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const LinkAlbumWithSong = async (id, body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/link-album/${id}`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const UpdateSong = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/update`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const UpdateSongVideo = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/create-update-song-video`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const deleteVideo = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/delete-video`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetVideosOfSong = async (songID) => {
    try {
        const data = await axios.get(`${API_URL}/songs/get-all-of-video/${songID}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetAllVideosAuthor = async () => {
    try {
        const data = await axios.get(`${API_URL}/songs/get-all-videos-author-name`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetAllVideosType = async () => {
    try {
        const data = await axios.get(`${API_URL}/songs/get-all-videos-type`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetAllVideos = async () => {
    try {
        const data = await axios.get(`${API_URL}/songs/get-all-videos`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const linkArtists = async (id, body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/create-artists/${id}`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const GetAllArtists = async (nameonly) => {
    try {
        const data = await axios.get(`${API_URL}/songs/get-artists`, {
            params: nameonly || { nameonly: false }
        })
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const linkCredits = async (id, body) => {
    try {
        const data = await axios.post(`${API_URL}/songs/create-credits/${id}`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getAllCreditorsName = async () => {
    try {
        const data = await axios.get(`${API_URL}/songs/get-all-creditors-name`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const getAlbumData = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/album/get/${id}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


// Get Album Detail for Project Page
const getAlbumDetail = async (id, params) => {
    try {
        const data = await axios.get(`${API_URL}/album/get-detail/${id}${createUrl({ type: params })}`)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}


const updateAlbum = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/album/update`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}

const getSEOData = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/get-user-metadata`, body)
            .then(res => {
                console.log(res);
                if (typeof res.data != "undefined") return (res.data);

            })
        return data;
    }
    catch (error) {
        console.log(error);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status);
            console.error('Error message:', error.response.data.message);
            if (typeof error.response.data != "undefined") return error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server');
            return { success: false }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error during request setup:', error.message);
            return { success: false }
        }
    }
}



export {
    getSEOData,
    getAllAlbumsName,
    GetAllVideosType,
    GetAllVideosAuthor,
    signup,
    signin,
    completeProfile,
    updateEmail,
    updatePassword,
    forgotPassword,
    loginUser,
    getLayout,
    updateLayoutImg,
    updateLayout,
    getSocialLinks,
    updateSocialLinks,
    getMailChimp,
    updateMailChimp,
    getPCA,
    updatePCA,
    getPV,
    updatePV,
    createAlbum,
    getAllAlbum,
    DeleteAlbum,
    getAlbumDetail,
    getAllAlbumHomepage,
    GetAllSongs,
    GetSongData,
    createSong,
    DeleteSong,
    LinkAlbumWithSong,
    GetAllArtists,
    UpdateSong,
    linkArtists,
    linkCredits,
    getAlbumData,
    updateAlbum,
    UpdateSongVideo,
    GetVideosOfSong,
    deleteVideo,
    LinkSongWithAlbum,
    GetAllVideos,
    getAllCreditorsName,
}