import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import { getMailChimp, updateMailChimp } from '../../ContextAPI/APIs/api'
import { useToast } from '../../ContextAPI/Components/notify'
import { useAuth } from '../../ContextAPI/Components/auth'

const EmailSignUp = () => {
    const { toastAlert } = useToast()
    const { user, getLoginUser } = useAuth();


    const [mailchimp, setmailchimp] = useState(null)
    const [signUpLabel, setsignUpLabel] = useState(null)
    const [signUpButtonLabel, setsignUpButtonLabel] = useState(null)

    const getData = async () => {
        let thisUser = user;
        if (thisUser == null) thisUser = await getLoginUser()

        const res = await getMailChimp({ type: thisUser.username });
        const data = res.message;

        if (!data) {
            return
        } else {
            setmailchimp(data.apiLink);
            setsignUpLabel(data.label);
            setsignUpButtonLabel(data.btnLabel);
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        const res = await updateMailChimp({ label: signUpLabel, apiLink: mailchimp, btnLabel: signUpButtonLabel });

        console.log(res);

        toastAlert(res.message, res.success ? "success" : "error")


    }

    useEffect(() => {
        getData();
    }, [])

    return <>
        <section>

            <DashHead />

            <div className="container-admin">
                <div className="row mb-5">

                    <div className="col-lg-4">
                        <Sidebar />
                    </div>

                    <div className="col-lg">
                        <h2>Email Sign Up</h2>

                        <form className="d-grid gap-3 my-3" action="">


                            <div className={`form-floating form-social social-icon-mailchimp`}>
                                <input type="text" class="form-control" id='mailchimp' value={mailchimp} onChange={(E) => setmailchimp(E.target.value)} placeholder='Mailchimp API Link' required />
                                <label for='mailchimp'>Mailchimp API Link</label>
                            </div>

                            <div className="form-floating">
                                <input type="text" className='form-control' name="signupLabel" id="signupLabel" value={signUpLabel} onChange={(E) => setsignUpLabel(E.target.value)} placeholder='Sign Up Label' required />
                                <label htmlFor="signupLabel">Sign Up Label</label>
                            </div>

                            <div className="form-floating">
                                <input type="text" className='form-control' name="signupBtnLabel" id="signupBtnLabel" value={signUpButtonLabel} onChange={(E) => setsignUpButtonLabel(E.target.value)} placeholder='Sign Up Button Label' required />
                                <label htmlFor="signupBtnLabel">Sign Up Button Label</label>
                            </div>
                            <button onClick={(e) => submit(e)} className='btn btn-primary'>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default EmailSignUp
