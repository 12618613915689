import React, { useEffect, useMemo, useState } from 'react'

import YOUTUBE from "../assets/svg/youtube.svg"
import SPOTIFY from "../assets/svg/spotify.svg"
import ITUNES from "../assets/svg/itunes.svg"
import TIDAL from "../assets/svg/tidal.svg"
import AMAZON_MUSIC from "../assets/svg/amazon-music.svg"
import SOUND_CLOUD from "../assets/svg/soundcloud.svg"
import FACEBOOK from "../assets/svg/facebook.svg"
import INSTAGRAM from "../assets/svg/instagram.svg"
import TWITTER from "../assets/svg/x-twitter.svg"
import TIKTOK from "../assets/svg/tiktok.svg"
import APPLE_PODCAST from "../assets/svg/apple-podcast.svg"

import WRISTBAND from "../assets/Luxtreaux-Wristband-Photo.jpg"
import { getAllAlbumHomepage, getLayout, getMailChimp, getPCA, getPV, getSocialLinks } from '../ContextAPI/APIs/api'
import { ARTIST, FILE_URL, defaultCreator } from '../config'

import VideoModal from '../components/VideoModal'
import { convertToTitleDash } from '../hooks/removeSpaceAddDash'
import { createUrlFront } from '../helpers/createUrlFront'
import GoogleTagLinkCreator from '../components/GoogleTagLinkCreator'
import { handleFireEvent } from '../helpers/gtm'



const Homepage = () => {
    const [layout, setLayout] = useState(null)

    async function GetLayout() {
        const res = await getLayout({ type: ARTIST ? ARTIST : defaultCreator })
        if (res.success) {
            setLayout(res.message)

        } else {
            setLayout(null)

        }
    }


    // Define a mapping between section IDs and corresponding components
    const sectionComponents = {
        "Social and Email": <MainBanner />,
        "Promotional CTA": <PromotionalCallToActionSection />,
        "Promotional Video": <PromotionalVideoSection />,
        "Releases": <AlbumsAndSongsSection />,
    };

    useEffect(() => {
        GetLayout()

    }, [])



    if (layout != null) {

        console.log("layout => => ", layout);

        // Sort sections based on the specified order of IDs
        const sortedSections = layout.map((section) => {
            if (section.active) {
                return React.cloneElement(sectionComponents[section.name], section);
            }
        });

        return sortedSections;
    }

};


function MainBanner(props) {
    return <section className="container-fluid main-header overlay bg-cover text-center p-lg-5 p-4" style={(typeof props.img != "undefined" && props.img != null && props.img != "") ? { backgroundImage: `url(${FILE_URL}/${props?.img})` } : null}>
        <div className="row">
            <div className="col d-grid gap-5">

                <SocialLinksSection />

                <MailchimpSection />

            </div>
        </div>
    </section>
}


function SocialLinksSection() {
    const [socialLinks, setSocialLinks] = useState(null)

    async function GetSocialLinks() {
        const res = await getSocialLinks({ type: ARTIST ? ARTIST : defaultCreator });
        if (res.success) setSocialLinks(res.message)
    }

    useMemo(() => {
        GetSocialLinks()

    }, [])


    if (socialLinks != null) {
        return <div className="socials d-grid gap-3">
            {(socialLinks.label != "" && socialLinks.label != null) && <h1 className="h3 text-white text-shadow" data-aos="fade-up" data-aos-offset="20" data-aos-delay="100">{socialLinks.label}</h1>}

            <ul data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20"
                className="d-flex flex-wrap gap-3 list-unstyled mx-auto justify-content-center social-group shadow p-2 rounded">
                <span className="visually-hidden">Yarley G's social media connetions</span>
                {/* {(socialLinks.youtube != "" && socialLinks.youtube != null) && <li>{<GoogleTagLinkCreator link={socialLinks.youtube} img={YOUTUBE} socialLinks={socialLinks} name={'Youtube'} />}</li>} */}
                {(socialLinks.youtube != "" && socialLinks.youtube != null) && <li onClick={(e) => handleFireEvent(e, "Youtube Click")}><img src={YOUTUBE} alt="Yarley G's Youtube" /></li>}
                {(socialLinks.spotify != "" && socialLinks.spotify != null) && <li onClick={(e) => handleFireEvent(e, "Spotify Click")}><img src={SPOTIFY} alt="Yarley G's Spotify" /></li>}
                {(socialLinks.appleMusic != "" && socialLinks.appleMusic != null) && <li onClick={(e) => handleFireEvent(e, "Apple Music Click")}><img src={ITUNES} alt="Yarley G's Apple Music" /></li>}
                {/*{(socialLinks.youtube != "" && socialLinks.youtube != null) && <li><a className="btn-square" href={socialLinks.youtube} onClick={(e) => handleFireEvent(e, "Youtube Click")} target='_blank'><img src={YOUTUBE} alt="Yarley G's Youtube" /></a></li>}
                {(socialLinks.spotify != "" && socialLinks.spotify != null) && <li><a className="btn-square" href={socialLinks.spotify} onClick={(e) => handleFireEvent(e, "Spotify Click")} target='_blank'><img src={SPOTIFY} alt="Yarley G's Spotify" /></a></li>}
                {(socialLinks.appleMusic != "" && socialLinks.appleMusic != null) && <li><a className="btn-square" href={socialLinks.appleMusic} onClick={(e) => handleFireEvent(e, "Apple Music Click")} target='_blank'><img src={ITUNES} alt="Yarley G's Apple Music" /></a></li>} */}
                {(socialLinks.tidal != "" && socialLinks.tidal != null) && <li><a className="btn-square" href={socialLinks.tidal} onClick={(e) => handleFireEvent(e, "Tidal Click")} target='_blank'><img src={TIDAL} alt="Yarley G's Tidal" /></a></li>}
                {(socialLinks.amazonMusic != "" && socialLinks.amazonMusic != null) && <li><a className="btn-square" href={socialLinks.amazonMusic} onClick={(e) => handleFireEvent(e, "Amazon Music Click")} target='_blank'><img src={AMAZON_MUSIC} alt="Yarley G's Amazon Music" /></a></li>}
                {(socialLinks.soundCloud != "" && socialLinks.soundCloud != null) && <li><a className="btn-square" href={socialLinks.soundCloud} onClick={(e) => handleFireEvent(e, "Soundclound Click")} target='_blank'><img src={SOUND_CLOUD} alt="Yarley G's Soundcloud" /></a></li>}
                {(socialLinks.fb != "" && socialLinks.fb != null) && <li><a className="btn-square" href={socialLinks.fb} onClick={(e) => handleFireEvent(e, "Facebook Click")} target='_blank'><img src={FACEBOOK} alt="Yarley G's Facebook" /></a></li>}
                {(socialLinks.insta != "" && socialLinks.insta != null) && <li><a className="btn-square" href={socialLinks.insta} onClick={(e) => handleFireEvent(e, "Instagram Click")} target='_blank'><img src={INSTAGRAM} alt="Yarley G's Instagram" /></a></li>}
                {(socialLinks.twitter != "" && socialLinks.twitter != null) && <li><a className="btn-square" href={socialLinks.twitter} onClick={(e) => handleFireEvent(e, "Twitter Click")} target='_blank'><img src={TWITTER} alt="Yarley G's X (Twitter)" /></a></li>}
                {(socialLinks.tiktok != "" && socialLinks.tiktok != null) && <li><a className="btn-square" href={socialLinks.tiktok} onClick={(e) => handleFireEvent(e, "Tiktok Click")} target='_blank'><img src={TIKTOK} alt="Yarley G's TikTok" /></a></li>}
            </ul>
        </div>
    }
}


function MailchimpSection() {
    const [mailchimp, setMailchimp] = useState(null)


    async function GetMailchimp() {
        const res = await getMailChimp({ type: ARTIST ? ARTIST : defaultCreator });
        if (res.success) setMailchimp(res.message)
    }

    useMemo(() => {
        GetMailchimp()

    }, [])


    if (mailchimp != null) {
        return <div className="d-grid gap-3">
            <h2 className="h3 text-white text-shadow" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                {(typeof mailchimp?.label != "undefined" && mailchimp?.label != "") ? mailchimp?.label : "Submit"}
            </h2>
            <form className="newsletter-form d-flex flex-column flex-md-row gap-3"
                action={mailchimp.apiLink}
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate=""
            >
                <div className='form-floating col'>
                    <input onClick={(e) => handleFireEvent(e, "Email Sign Up Input Click")} className="form-control" type="email" name='EMAIL' placeholder="Please enter your email" />
                    <label htmlFor="form-control">Please enter your email</label>
                </div>
                <button onClick={(e) => handleFireEvent(e, "Email Sign Up Button Click")} className="btn btn-primary">{(typeof mailchimp?.btnLabel != "undefined" && mailchimp?.btnLabel != "") ? mailchimp?.btnLabel : "Submit"}</button>
            </form>
        </div>
    }
}


function PromotionalCallToActionSection(props) {
    const [PCA, setPCA] = useState(null)

    // Promotional Call
    async function GetPCA() {
        const res = await getPCA({ type: ARTIST ? ARTIST : defaultCreator });
        if (res.success) setPCA(res.message)
    }


    useMemo(() => {
        GetPCA()

    }, [])


    if (PCA != null) {
        return <section data-aos="fade-up" data-aos-easing="ease" data-aos-offset="20" data-aos-delay="100" style={(typeof props.img != "undefined" && props.img != null && props.img != "") ? { backgroundImage: `url(${FILE_URL}/${props?.img})` } : null}>
            <div className="d-grid gap-3 p-lg-5 p-4 text-center">
                <h2>{PCA.title}</h2>
                <div className="button-action">
                    <a href={PCA.link} onClick={(e) => handleFireEvent(e, `${PCA.label} Click`)} className="btn btn-primary">{PCA.label}</a>
                </div>
            </div>
            <img src={`${FILE_URL}/${PCA.img}`} className="img-fluid w-100" alt="Glow in the dark Make Your Statement wristbands by Luxtreaux"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = WRISTBAND;
                }}
            />
        </section >
    }
}


function PromotionalVideoSection() {
    const [PV, setPV] = useState(null)

    // Promotional Video
    async function GetPV() {
        const res = await getPV({ type: ARTIST ? ARTIST : defaultCreator });
        if (res.success) setPV(res.message)
    }


    useMemo(() => {
        GetPV()

    }, [])


    if (PV != null) {
        return <section>
            <div className="video-item d-grid gap-3" data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20" style={(typeof PV.img != "undefined" && PV.img != null && PV.img != "") ? { backgroundImage: `url(${FILE_URL}/${PV?.img})` } : null}>
                <div className="video-detail">
                    <div>
                        <VideoModal videoId={PV?.link} videoTitle={PV.title} handleFireEvent={(e) => handleFireEvent(e, `${PV.title} Click`)} />
                    </div>
                    <div className="video-description">
                        <h3 className="d-grid gap-3">
                            <div>{PV.author}</div>
                            <div className="h2">{PV.title}</div>
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    }
}


function AlbumsAndSongsSection() {
    const [activeSong, setsong] = useState('')
    const [allAlbums, setAllAlbums] = useState([])
    const [videos, setVideos] = useState([])
    const [ShowMenu, setShowMenu] = useState(false)

    const activeHandler = (e, name) => {
        const songName = e.target.id;
        setsong(songName);
        handleFireEvent(e, `${name} Scroll To Click`)
    }

    async function GetAlbums() {
        const res = await getAllAlbumHomepage({ type: ARTIST });
        if (res.success) {
            setVideos(res.videos)
            setAllAlbums(res.message)
        }
    }

    const FilterForPrimary = (array) => {
        const primaryArray = array.filter(e => e.type == "primary")
        return primaryArray
    }

    const filterForFeatured = (array) => {
        const featuredArray = array.filter(e => e.type == "featured-artist")
        return featuredArray
    }


    useMemo(() => {
        GetAlbums()

    }, [])


    if (allAlbums.length > 0) {
        return <section className="releases" id="releases">
            <div className="p-lg-5 p-4 overflow-hidden">
                <h2 className="text-center" data-aos="zoom-out" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                    Releases
                </h2>
            </div>


            <div className="bg-dark position-relative text-white pb-lg-5" data-bs-theme="dark">
                <div className="sticky-top bg-dark">
                    <div className="container">
                        <div className="d-lg-none mb-3 d-grid p-3">
                            <button onClick={() => setShowMenu(true)} className="btn btn-outline-light toggle-menu">
                                Open Song Menu
                            </button>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className={`side-menu releases-list sticky-lg-top w-100 scroll-fade mt-lg-5 ${ShowMenu && 'show'}`}>
                                <div className="d-lg-none mb-3 d-grid">
                                    <a className="btn btn-outline-light toggle-menu" onClick={() => setShowMenu(false)}>
                                        Close Song Menu
                                    </a>
                                </div>


                                <div className="side-scrolling pt-lg-3">
                                    <div className="accordion accordion-sidebar d-grid sticky-top overflow-auto" id="accordionUserSidebar">

                                        {
                                            allAlbums.length > 0 && allAlbums.map((album, i) => {
                                                if ((album.songs).length > 0) {
                                                    return <div key={i} className="accordion-item">
                                                        <h3 className="accordion-header">
                                                            <button onClick={(e) => handleFireEvent(e, `${album?.title} Accordion Click`)} className="accordion-button lh-1" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion${album._id}`} aria-expanded="true" aria-controls="accordion1">
                                                                {album?.title}
                                                            </button>
                                                            <div className='d-grid'>
                                                                <a className="btn btn-outline-light py-0" onClick={(e) => handleFireEvent(e, `${album?.title} Project View Click`)} href={`/project/${album.slug}${createUrlFront({ type: album?.creator || "" })}`}>View Project</a>
                                                            </div>
                                                        </h3>
                                                        <div id={`accordion${album._id}`} className="accordion-collapse collapse show">
                                                            <div className="accordion-body py-3">
                                                                <ol className="list-group-numbered scroll-to-content p-0 mb-0" id="stream-list">
                                                                    <span className="visually-hidden">{album?.title}</span>
                                                                    {
                                                                        (album.songs).map((song, i) => {
                                                                            return <li key={i} onClick={() => setShowMenu(false)}>
                                                                                <a id={song._id} className={`list-group-item ${activeSong == song._id && 'active'} `} onClick={(e) => activeHandler(e, song.title)} href={`#${convertToTitleDash(song.title)}`}>{song.title}</a>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ALL SONGS SECTION */}
                        {
                            allAlbums.length > 0 &&
                            <div className="col-lg text-center scroll-fade">
                                <div className="d-grid gap-5 pt-lg-0 pt-4">

                                    {
                                        allAlbums.map((album, i) => {
                                            return (album.songs).length > 0 && (album.songs).map((song, j) => {
                                                console.log(JSON.stringify(song), 'this is the song');
                                                return <article key={j} id={convertToTitleDash(song.title)} className="d-grid gap-3 pt-7 mt-n7 mt-lg-n3">
                                                    <div className="d-grid gap-3">
                                                        <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100"
                                                            data-aos-offset="20">
                                                            <h4 className="h1">{song.title}</h4>
                                                            <div className="fw-normal h3" style={{ textTransform: "capitalize" }}>
                                                                {
                                                                    typeof song.artists !== "undefined" && song.artists.length > 0 && FilterForPrimary(song.artists)?.map((artist, i) => {
                                                                        if (artist?.type === "primary") {
                                                                            return (
                                                                                <span key={i} >
                                                                                    {artist.name}
                                                                                    {i < (FilterForPrimary(song.artists))?.length - 1 && ','} {/* Add comma if not the last artist */}
                                                                                </span>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })
                                                                }
                                                                <br />
                                                                featuring &nbsp;

                                                                {
                                                                    typeof song.artists !== "undefined" && song.artists.length > 0 && filterForFeatured(song.artists)?.map((artist, i) => {
                                                                        console.log(artist, 'logthislogthis');
                                                                        if (artist?.type === "featured-artist") {
                                                                            return (
                                                                                <>
                                                                                    <span key={i} style={{ textTransform: "capitalize" }}>
                                                                                        {artist.name}
                                                                                        {i < filterForFeatured(song.artists)?.length - 1 && ','} {/* Add comma if not the last artist */}
                                                                                    </span>
                                                                                </>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a href={`/song/${song.slug}${createUrlFront({ type: song?.creator || "" })}`} onClick={(e) => handleFireEvent(e, `${song.title} Song View Click`)} className="btn btn-outline-light">View Credits, Lyrics and more</a>
                                                        </div>
                                                    </div>

                                                    {
                                                        ((song.youtube != "" && song.youtube != null) ||
                                                            (song.spotify != "" && song.spotify != null) ||
                                                            (song.appleMusic != "" && song.appleMusic != null) ||
                                                            (song.tidal != "" && song.tidal != null) ||
                                                            (song.amazonMusic != "" && song.amazonMusic != null) ||
                                                            (song.soundCloud != "" && song.soundCloud != null)) &&
                                                        <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                                            <div className="d-grid gap-3">
                                                                <h5 className="h3">Stream on</h5>
                                                            </div>

                                                            {/* STREAMING LINKS OF SONG */}
                                                            <div className="row g-3">
                                                                {
                                                                    (song.youtube != "" && song.youtube != null) &&
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <a onClick={(e) => handleFireEvent(e, `${song.title} Youtube Click`)} className="btn btn-play" href={song.youtube} target='_blank'>
                                                                            <img width="48" src={YOUTUBE} alt="" />
                                                                            Youtube
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {
                                                                    (song.spotify != "" && song.spotify != null) &&
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <a onClick={(e) => handleFireEvent(e, `${song.title} Spotify Click`)} className="btn btn-play" href={`${song.spotify}`} target='_blank'>
                                                                            <img width="48" src={SPOTIFY} alt="" />
                                                                            Spotify
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {
                                                                    (song.appleMusic != "" && song.appleMusic != null) &&
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <a onClick={(e) => handleFireEvent(e, `${song.title} Apple Music Click`)} className="btn btn-play" href={song.appleMusic} target='_blank'>
                                                                            <img width="48" src={ITUNES} alt="" />
                                                                            Apple Music
                                                                        </a>
                                                                    </div>
                                                                }

                                                                {
                                                                    (song.tidal != "" && song.tidal != null) &&
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <a onClick={(e) => handleFireEvent(e, `${song.title} Tidal Click`)} className="btn btn-play" href={song.tidal} target='_blank'>
                                                                            <img width="48" src={TIDAL} alt="" />
                                                                            Tidal
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {
                                                                    (song.amazonMusic != "" && song.amazonMusic != null) &&
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <a onClick={(e) => handleFireEvent(e, `${song.title} Amazon Music Click`)} className="btn btn-play" href={song.amazonMusic} target='_blank'>
                                                                            <img width="48" src={AMAZON_MUSIC} alt="" />
                                                                            Amazon Music
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {
                                                                    (song.soundCloud != "" && song.soundCloud != null) &&
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <a onClick={(e) => handleFireEvent(e, `${song.title} Sound Cloud Click`)} className="btn btn-play" href={song.soundCloud} target='_blank'>
                                                                            <img width="48" src={SOUND_CLOUD} alt="" />
                                                                            Soundcloud
                                                                        </a>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {/* VIDEOS SECTION OF SONG */}
                                                    {
                                                        (videos).length > 0 &&
                                                        <div className="row g-3">
                                                            {
                                                                (videos).map((video, k) => {
                                                                    if (song?._id == video?.song) {
                                                                        return <div key={k} className="col-md-6 flex-grow-1">
                                                                            <div className={`video-item d-grid gap-3`} data-aos="zoom-in" style={{ backgroundImage: `url(${FILE_URL}/${video?.img})` }}
                                                                                data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                                                                <div className="video-detail">
                                                                                    <div>
                                                                                        <VideoModal videoId={video.videoYoutube} videoTitle={video.videoType} handleFireEvent={(e) => handleFireEvent(e, `${song.title} ${video.videoType} Click`)} />

                                                                                    </div>
                                                                                    <div className="video-description d-grid gap-3">
                                                                                        <h4 className="h2">{video.videoType}</h4>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                ((
                                                                                    (video?.podcastYoutube != "" && video?.podcastYoutube != null && typeof video?.podcastYoutube != "undefined") ||
                                                                                    (video?.podcastSpotify != "" && video?.podcastSpotify != null && typeof video?.podcastSpotify != "undefined") ||
                                                                                    (video?.podcastItunes != "" && video?.podcastItunes != null && typeof video?.podcastItunes != "undefined") ||
                                                                                    (video?.podcastAmazonMusic != "" && video?.podcastAmazonMusic != null && typeof video?.podcastAmazonMusic != "undefined")
                                                                                )
                                                                                    && (video.podcastToggle == true))
                                                                                &&
                                                                                <div className='mt-3' data-aos="fade-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                                                                    <ul className="d-flex gap-3 w-100 list-unstyled mx-auto justify-content-center social-group shadow p-2 rounded">
                                                                                        <span className="visually-hidden">I Love You Lord 'Breaking Down The Beat' Videos on other platforms</span>
                                                                                        {
                                                                                            (video?.podcastYoutube != "" && video?.podcastYoutube != null && typeof video?.podcastYoutube != "undefined") &&
                                                                                            <li>
                                                                                                <a onClick={(e) => handleFireEvent(e, `${song.title} ${video.videoType} Youtube Podcast Click`)} className="btn-square" href={video?.podcastYoutube}>
                                                                                                    <img src={YOUTUBE} alt={"Podcast Youtube"} />
                                                                                                </a>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (video?.podcastSpotify != "" && video?.podcastSpotify != null && typeof video?.podcastSpotify != "undefined") &&
                                                                                            <li>
                                                                                                <a onClick={(e) => handleFireEvent(e, `${song.title} ${video.videoType} Spotify Podcast Click`)} className="btn-square" href={video?.podcastSpotify}>
                                                                                                    <img src={SPOTIFY} alt={"Podcast Spotify"} />
                                                                                                </a>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (video?.podcastItunes != "" && video?.podcastItunes != null && typeof video?.podcastItunes != "undefined") &&
                                                                                            <li>
                                                                                                <a onClick={(e) => handleFireEvent(e, `${song.title} ${video.videoType} Itunes Podcast Click`)} className="btn-square" href={video?.podcastItunes}>
                                                                                                    <img src={APPLE_PODCAST} alt={"Podcast Itunes"} />
                                                                                                </a>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            (video?.podcastAmazonMusic != "" && video?.podcastAmazonMusic != null && typeof video?.podcastAmazonMusic != "undefined") &&
                                                                                            <li>
                                                                                                <a onClick={(e) => handleFireEvent(e, `${song.title} ${video.videoType} Amazon Music Podcast Click`)} className="btn-square" href={video?.podcastAmazonMusic}>
                                                                                                    <img src={AMAZON_MUSIC} alt={"Podcast AmazonMusic"} />
                                                                                                </a>
                                                                                            </li>
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                </article>
                                            })
                                        })
                                    }

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    }
}



export default Homepage