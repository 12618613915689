import { useEffect, useReducer, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GetSongData, UpdateSong, getAllAlbum, getAllCreditorsName, linkArtists, linkCredits, } from "../../../ContextAPI/APIs/api";
import { useToast } from "../../../ContextAPI/Components/notify"
import { useLoader } from "../../../ContextAPI/Components/loaderContext";
import TypeAheadLoops from "../../../components/TypeAheadLoops";
import TypeAhead from "../../../components/TypeAhead";
import { moveElementOnScreenTop } from "../../../helpers/util";
import { useCreditorsArtist } from "../../../ContextAPI/Components/CreditorsArstisState";




const Artists = ({ allArtistsData, data, GetAllArtists, setArtistNameList }) => {


    const { songID } = useParams();
    const navigate = useNavigate();
    const { hash, pathname } = useLocation();
    const { toastAlert } = useToast();
    const { loader, showLoader, hideLoader } = useLoader()
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const { CreditorsArtistList, setCreditorsArtistList } = useCreditorsArtist()

    if ((pathname == '/admin/edit-song/' || pathname == '/admin/edit-song') && (!songID)) {
        toastAlert('Invalid Request', "error");
        navigate('/admin/add-new')
    }


    const [PrimaryArtist, setPrimaryArtist] = useState({ type: "primary", name: "", });
    const artistsTemplate = {
        type: "primary",
        name: "",
    };

    const [artistsData, setartistsData] = useState([artistsTemplate]);

    const artistsTypeValueHandler = (e, i) => {
        const updatedData = artistsData.map((item, index) => {
            if (index === i) {
                return { ...item, type: e.target.value };
            } else {
                return item;
            }
        });

        setartistsData(updatedData);
    };

    const artistsNameValueHandler = (e, i) => {
        const updatedData = artistsData.map((item, index) => {
            if (index === i) {
                return { ...item, name: e };
            } else {
                return item;
            }
        });

        setartistsData(updatedData);
    };


    const GetAllCreditorsName = async () => {
        const res = await getAllCreditorsName()
        if (res.success) {

            function getUniqueArtists(credits) {
                // Create a Set to store unique names
                const uniqueNames = new Set();

                // Filter out duplicates and convert names to lowercase for case-insensitivity
                const uniqueArtists = credits.filter(credit => {
                    const lowercaseName = credit.name.toLowerCase();
                    if (!uniqueNames.has(lowercaseName)) {
                        uniqueNames.add(lowercaseName);
                        return true;
                    }
                    return false;
                });

                return uniqueArtists;
            }


            var unique = getUniqueArtists(res.message);
            console.log(unique, 'Unique credits Name');

            setCreditorsArtistList(unique)
        }
    }

    const contentDelete = (i, arr, setArr) => {
        const temp = arr;
        temp.splice(i, 1);
        setArr(temp);
        forceUpdate();
    };

    const updateAristData = async (e) => {
        e.preventDefault()
        showLoader()
        console.log(songID, [PrimaryArtist, ...artistsData]);
        if (typeof songID != "undefined" && songID != null && songID != "") {
            const res = await linkArtists(songID, [PrimaryArtist, ...artistsData]);
            console.log(res);

            toastAlert(res.success ? res.message : res.message, res.success ? "success" : "error");

            GetAllCreditorsName()

            const res2 = await GetAllArtists({ nameonly: true })
            console.log("GetAllArtists -> ", res2.message);
            const temp = (res2.message)//.map((e) => (e.name))
            // function getUniqueArtists(artists) {
            //     // Create a Set to store unique names
            //     const uniqueNames = new Set();

            //     // Filter out duplicates and convert names to lowercase for case-insensitivity
            //     const uniqueArtists = artists.filter(artist => {
            //         const lowercaseName = artist.name.toLowerCase();
            //         if (!uniqueNames.has(lowercaseName)) {
            //             uniqueNames.add(lowercaseName);
            //             return true;
            //         }
            //         return false;
            //     });

            //     return uniqueArtists;
            // }
            // var unique = getUniqueArtists(temp);
            // console.log('Unique Artist Name');
            // console.log(unique);
            // // setArtistNameList(unique)
            // setCreditorsArtistList(unique)



        }

        window.document.getElementById(`streaming-links-content`).classList.add("show");
        window.document.getElementById(`youtube`).focus();
        window.document.getElementById(`artists-content`).classList.remove("show");
        moveElementOnScreenTop("#accordionAdminSettings > form:nth-child(3) > section");
        hideLoader()
    }


    const handleCancel = () => {

        document.getElementById('artist-content-accordion-btn').classList.add('collapsed')

        if ((allArtistsData != null) && ((allArtistsData).length > 0)) {
            const finalData = allArtistsData.map(e => {
                return { name: e.name, type: e.type }
            })
            if ((finalData != 'null') && (finalData.length > 0)) {
                const primaryartist = finalData.shift();
                setPrimaryArtist(primaryartist);
                const otherartists = finalData;
                setartistsData(otherartists);
            }

        } else {
            setPrimaryArtist({ name: '', type: 'primary' })
            setartistsData([artistsTemplate])
        }



    }

    useEffect(() => {

        GetAllCreditorsName()

        if ((allArtistsData != null) && ((allArtistsData).length > 0)) {
            const finalData = allArtistsData.map(e => {
                return { name: e.name, type: e.type }
            })
            if ((finalData != 'null') && (finalData.length > 0)) {
                const primaryartist = finalData.shift();
                setPrimaryArtist(primaryartist);
                const otherartists = finalData;
                setartistsData(otherartists);
            }

        } else {
            setartistsData([artistsTemplate])
        }


    }, [allArtistsData]);




    return (

        <form onSubmit={(e) => updateAristData(e)}>
            <section className="accordion-item d-grid shadow rounded-3">
                <fieldset>
                    <legend className="accordion-header accordion-sticky-header sticky-top rounded-3">
                        <h3>
                            <button id="artist-content-accordion-btn" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#artists-content" aria-expanded="false" aria-controls="artists-content"> Artists </button>
                        </h3>
                    </legend>

                    <div id="artists-content" class="accordion-collapse collapse" data-bs-parent="#accordionAdminSettings" >
                        <div class="accordion-body">
                            <div id="Artists" className="d-grid gap-3 px-3">
                                <div className="d-flex gap-3 justify-content-end py-3 border-top sticky-top accordion-sticky-actions">
                                    <button type="button" onClick={handleCancel} aria-label="Cancel" className="btn btn-secondary" data-bs-toggle="collapse" data-bs-target="#artists-content" aria-expanded="false" aria-controls="artists-content"> Cancel </button>
                                    <button aria-label="Save Artist Data and Continue" className="btn btn-primary"> Save and Continue </button>
                                </div>
                                <div>
                                    <h4>Primary Artist</h4>
                                    <TypeAhead
                                        list={CreditorsArtistList.map(e => e.name)}
                                        state={PrimaryArtist}
                                        setState={setPrimaryArtist}
                                        attrValue={"primaryArtist"}
                                        label={"Primary Artist Name"}
                                        ariaLabel={'Add Primary Artist'}
                                        suggestionDivId={"suggestionDivPrimaryArtist"}
                                        keyProp="name"
                                    />
                                </div>

                                {artistsData.map((allData, i) => {
                                    console.log("allData", allData);
                                    return (
                                        <div key={i}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4>Artist {i + 2}</h4>
                                                <button type="button" className="icon-btn icon-btn-danger" aria-label={`Delete Artist ${i + 2}`} onClick={() => contentDelete(i, artistsData, setartistsData)}>
                                                    <span className="icon-delete"></span>
                                                </button>
                                            </div>

                                            <div className="d-grid gap-3">
                                                <div class="form-floating">
                                                    <select class="form-select" aria-label={`Select Artist ${i + 2} Type`} id={`type${i + 2}`} name={`type${i + 1}`} onChange={(e) => artistsTypeValueHandler(e, i)} value={allData.type} >
                                                        <option value="primary" selected> Primary</option>
                                                        <option value="featured-artist"> Featured Artist</option>
                                                    </select>
                                                    <label for={`type${i + 2}`}> Type </label>
                                                </div>
{}
                                                <TypeAheadLoops
                                                    list={CreditorsArtistList}
                                                    valueKey={'name'}
                                                    stateData={allData}
                                                    updateStateFunction={artistsNameValueHandler}
                                                    index={i}
                                                    attrValue={`name${i + 2}`}
                                                    label={"Artist Name"}
                                                    ariaLabel={`Add Artist ${i + 2} Name`}
                                                    suggestionDivId={`suggestionDivOtherArtists${i + 2}`}
                                                    valueProp="name"
                                                    keyProp="name"
                                                />

                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="">
                                    <h4 className="">Add Another (Optional)</h4>
                                    <div className="d-grid">
                                        <button type="button" onClick={() => setartistsData([...artistsData, { type: "primary", name: "" }])} aria-label={`Add Another Artist Optional`} className="btn btn-secondary" >Add Another</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </section>
        </form>

    )
}

export default Artists
