import React from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import { Link } from 'react-router-dom'

const AddNew = () => {

    return <>
        <section>

            <DashHead />

            <div className="container-admin">


                <div className="row mb-5 ">
                    <div className="col-lg-4 position-relative">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2 className='heading-2'>Add New</h2>

                        <div className='row my-3 row-gap-2'>
                            <div className='col'>
                                <div className='d-grid'>
                                    <Link to={'/admin/add-new-song'} className='btn btn-secondary'>
                                        <span className='icon-song fs-2'></span>
                                        <div>Song</div>
                                    </Link>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-grid'>
                                    <Link to={'/admin/add-new-album'} className='btn btn-secondary'>
                                        <span className='icon-song fs-2'></span>
                                        <div>Album / Project</div>
                                    </Link>
                                </div>
                            </div>

                            {/* <button onClick={() => navigate('/admin/add-new-song')} className='btn border-primary w-100 me-2'>
                                <span className='icon-song text-primary h3'></span>
                                <p className='text-primary fw-normal m-0'>Song</p>
                            </button>

                            <button onClick={() => navigate('/admin/add-new-album')} className='btn border-primary w-100 ms-2'>
                                <span className='icon-album text-primary h3'></span>
                                <p className='text-primary fw-normal m-0'>Album / Project</p>
                            </button> */}

                        </div>

                    </div>
                </div>
            </div>

        </section>
    </>
}

export default AddNew
