import React, { useEffect } from 'react'

const ImageComp = ({ myfile, setmyfile, text, forr, setFileName }) => {

    function RemoveFile() {
        // if (typeof setFileName != "undefined" && setFileName != "" && setFileName != null)  
        setFileName({ name: "" })
        setmyfile('')
    }

    useEffect(() => {
        // THIS DOCUMENT IS DEPENDEND ON myfile STATE.... IF IT IS REMOVE THEN IT WILL THROW AN ERROR
        (document.getElementById(forr)).value = ''
    }, [myfile])

    return <>
        {
            myfile && myfile.name != undefined && myfile.name != '' ?
                <>
                    <div className="input-group-text">
                        <span className='icon-photo'></span>
                        <span className='icon-check-circle text-success'></span>
                        <button className='btn btn-link-danger p-0' onClick={() => RemoveFile()}>Remove "{myfile?.name}"</button>
                    </div>
                </>
                :
                <>
                    <label className="input-group-text" for={forr}>
                        <span className='icon-photo'></span>
                        <span className='icon-check-circle text-success'></span>
                        {text}
                    </label>
                </>
        }
    </>
}

export default ImageComp
