import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../assets/svg/zintune-logo.svg'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useToast } from '../ContextAPI/Components/notify';


import { forgotPassword } from '../ContextAPI/APIs/api'

const ForgotPass = () => {

    const { toastAlert } = useToast()


    const [email, setEmail] = useState(null)

    const emailSent = () => toast("Email has been sent to reset your password", {
        draggable: true,
        theme: "colored",
        className: "react-toast-success",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
    });


    const submit = async (e) => {
        e.preventDefault();

        const res = await forgotPassword(email)

        toastAlert(res.message, res.success ? "success" : "error")
        if (res.success) {
            return emailSent();
        }
    }


    return <>
        <ToastContainer style={{ width: 'max-content', }} />

        <div className="skippy visually-hidden-focusable overflow-hidden">
            <div className="container">
                <a className="d-inline-flex p-2 m-1 link-primary" href="#email">Skip to sign in</a>
            </div>
        </div>

        <div className="container-form text-center py-5">

            <main>
                <section>

                    <Link to={'/sign-in'} className="d-block">
                        <img className="zintune-logo" src={logo} alt="ZinTunes" />
                    </Link>

                    <div id="admin-intro">
                        <span className="h3">Welcome to Zintunes</span>
                        <p>Interactive Platform to create your music journey</p>
                    </div>

                    <h1 aria-describedby="admin-intro">Forgot Password</h1>

                    <form className="d-grid gap-3" onSubmit={(e) => submit(e)} >
                        <div className="form-floating">
                            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="email" placeholder="name@example.com" required />
                            <label htmlFor="email">Please enter your email</label>
                        </div>
                        <button className="btn btn-primary" type="submit">Submit</button>
                    </form>
                    <div className="d-grid">
                        <Link to={'/sign-in'} className="btn btn-link">Cancel</Link>
                    </div>

                </section>
            </main>
            <footer className="my-5">
                <p>© 2023 YNO Designs LLC</p>
            </footer>

        </div>


        {/* <section className='py-5'>
            <div className="container">
                <div className='row mx-0 mb-5 text-center d-flex justify-content-center'>
                    <div>
                        <img className='' src={logo} alt="" />
                    </div>
                    <h3>Welcome to Zintunes</h3>
                    <p className=''>Interactive Platform to create your music journey</p>
                    <h1 className='mt-2'>Forgot Password</h1>
                    <div className="col-lg-5 col-md-7 col-12">
                        <form className="row g-3 mt-2 mx-0">
                            <div className='form-floating p-0'>
                                <input type="email" className='form-control' placeholder='Please enter your email' required />
                                <label htmlFor="floatingInput">Please enter your email</label>
                            </div>
                            <button onClick={() => emailSent()} className='btn btn-primary my-form-btn'>Submit</button>
                            <Link to={'/sign-in'} className='text-primary fw-bold my-4'> Cancel </Link>
                        </form>
                    </div>
                    <p className='fw-medium'>© 2023 YNO Designs LLC</p>

                </div>
            </div>
        </section> */}
    </>
}

export default ForgotPass
