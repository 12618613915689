import React, { useEffect } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'

import { updateEmail } from '../../ContextAPI/APIs/api';
import { useState } from 'react';
import { useToast } from '../../ContextAPI/Components/notify';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useLoader } from '../../ContextAPI/Components/loaderContext';


const AdminEmail = () => {
    const { toastAlert } = useToast();
    const { showLoader, hideLoader } = useLoader();
    const { user, getLoginUser } = useAuth();


    const [email, setemail] = useState(null)



    const update = async (e) => {
        e.preventDefault();
        showLoader()
        const res = await updateEmail({ email })

        toastAlert(res.message, res.success ? "success" : "error")
        if(res.success) getLoginUser()

        hideLoader()
    }


    useEffect(() => {
        if (user === null) {
            return
        }
        else {
            setemail(user.email)
        }
    }, [user])


    return <>
        <section>

            <DashHead />

            <div className="container-admin">


                <div className="row mb-5">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2 className=''>Email</h2>
                        <form onSubmit={(e) => update(e)} className="d-grid gap-3 my-3" action="">
                            <div className="form-floating">
                                <input type="email" id='email' name='email' className='form-control' value={email} onChange={(e) => setemail(e.target.value)} placeholder='Email' required />
                                <label htmlFor="email">Email</label>
                            </div>
                            <button className='btn btn-primary'>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default AdminEmail
