import React, { useMemo, useRef, useState, useEffect } from "react";



const TypeAheadLoops = ({ list, valueKey, stateData, updateStateFunction, index, attrValue, label, ariaLabel, suggestionDivId, valueProp, keyProp }) => {
  const [inputValue, setInputValue] = useState(stateData[valueKey] || '');
  const [filteredNames, setFilteredNames] = useState(list);
  const [selectedName, setSelectedName] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    setFilteredNames(list);
  }, []);

  useEffect(() => {
    setInputValue(stateData[valueKey] || '');
  }, [stateData, valueKey]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    updateStateFunction(value, index);
    // Filter names based on user input

    console.log(list, 'asdihaida');

    if (list) {
      const filtered = list.filter((item) => {
        return (item?.[keyProp]?.toLowerCase())?.includes(value?.toLowerCase()?.trim())
      });

      if (value.length > 0) {
        filtered.sort((a, b) => {
          console.log(a, b, 'a => b');
          const nameA = a[keyProp].toUpperCase();
          const nameB = b[keyProp].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }

      console.log("sorted", filtered);
      setFilteredNames(filtered);
      setDropdownVisible(filtered.length > 0);
    }

  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        navigateDropdown(1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        navigateDropdown(-1);
        break;
      case 'Enter':
        e.preventDefault();
        if (filteredNames.length === 0) {
          // Handle case when there are no filtered names
        } else if (selectedName) {
          handleNameSelect(selectedName);
        } else if (filteredNames.length > 0) {
          handleNameSelect(filteredNames[0]);
        }
        break;
      case 'Escape':
        if (inputValue === '' && selectedName) {
          setInputValue(selectedName[keyProp]);
        }
        setDropdownVisible(false);
        break;
      default:
        break;
    }
  };

  const handleNameSelect = (name) => {
    console.log("handleNameSelect => ", name[keyProp]);
    setSelectedName(name[keyProp]);
    setInputValue(name[keyProp]);
    setDropdownVisible(false);
    inputRef.current.focus();

    updateStateFunction(name[keyProp], index);
  };

  const navigateDropdown = (step) => {
    const currentIndex = filteredNames.indexOf(selectedName);
    let newIndex = currentIndex + step;

    if (newIndex < 0) {
      newIndex = filteredNames.length - 1;
    } else if (newIndex >= filteredNames.length) {
      newIndex = 0;
    }

    setSelectedName(filteredNames[newIndex]);
  };

  const handleInputBlur = () => {
    // Use setTimeout to wait for the potential click on the dropdown
    setTimeout(() => {
      setDropdownVisible(false);
    }, 200);
  };

  return (
    <div className="dropdown dropdown-musto-typeahead">
      <div className="form-floating"><input
        id={attrValue}
        name={attrValue} type="text"
        value={inputValue}
        onFocus={handleInputChange}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        placeholder={`Type a ${label.toLowerCase()}...`}
        ref={inputRef}
        className={`form-control ${inputValue.length < 1 && 'is-invalid'}`}
        aria-haspopup="listbox"
        aria-expanded={isDropdownVisible}
        role="combobox"
        aria-autocomplete="list"
        aria-owns={suggestionDivId}
        aria-activedescendant={selectedName ? selectedName[keyProp] : null}
        data-toggle="dropdown"
        required
      />
        {
          inputValue.length < 1 &&
          <div id={`${attrValue}-error`} class="invalid-feedback">
            Please enter a {label.toLowerCase()}
          </div>
        }
        <label for={attrValue}>{label}</label>
      </div>

      {isDropdownVisible && (
        <ul
          id={suggestionDivId}
          role="listbox"
          aria-labelledby="typeahead-label"
          className="dropdown-menu show"
        >
          {filteredNames.map((item, index) => (
            <li
              key={index}
              role="option"
              aria-selected={item === selectedName}
              id={item[keyProp]}
              className={`dropdown-item ${item === selectedName ? 'active' : ''}`}

            >
              <button
                type="button"
                className="w-100"
                onClick={() => handleNameSelect(item)}
              >
                {item[keyProp]}
              </button>
            </li>
          ))}
        </ul>
      )}

      {selectedName && (
        <p className="mt-2">
          {/* Selected: {selectedName} - {selectedName} */}
        </p>
      )}
    </div>
  );
};


export default TypeAheadLoops;