import React, { useEffect, useReducer, useState } from "react";
import Sidebar from "../../components/dashboard-components/Sidebar";
import DashHead from "../../components/dashboard-components/dashHead";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteAlbum, GetAllSongs, LinkSongWithAlbum, getAlbumData, updateAlbum } from "../../ContextAPI/APIs/api";
import { useToast } from '../../ContextAPI/Components/notify';
import { useLoader } from "../../ContextAPI/Components/loaderContext";
import ImageComp from "../../components/dashboard-components/ImageComp";
import { useSidebar } from "../../ContextAPI/Components/sidebar";
import { scrollToElement } from "../../helpers/util";




const EditAlbum = () => {
  const navigate = useNavigate();
  const { albumID } = useParams();
  const { getSongsAndAlbums } = useSidebar();
  const { showLoader, hideLoader } = useLoader();
  const { toastAlert } = useToast();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [Title, setTitle] = useState("");
  const [_id, set_id] = useState("");
  const [imgName, setImgName] = useState(null);
  const [Artwork, setArtwork] = useState(null);
  const [StreamingLinks, setStreamingLinks] = useState({
    socialLabel: "",
    youtube: "",
    spotify: "",
    "apple-music": "",
    tidal: "",
    "amazon-music": "",
    soundcloud: "",
  });

  let socialLinks = [
    {
      icon: "youtube",
      label: "Youtube",
    },
    {
      icon: "spotify",
      label: "Spotify",
    },
    {
      icon: "apple-music",
      label: "Apple Music",
    },
    {
      icon: "tidal",
      label: "Tidal",
    },
    {
      icon: "amazon-music",
      label: "Amazon Music",
    },
    {
      icon: "soundcloud",
      label: "Soundcloud",
    },
  ];

  const [songs, setsongs] = useState([]);
  const [filteredSongs, setfilteredSongs] = useState([])

  const [addedSongs, setaddedSongs] = useState([]);

  const filterSong = (value) => {
    if (value) {
      const found = songs.filter((e) => e.title.toLowerCase().includes(value.toLowerCase()));
      setfilteredSongs(found)
    } else {
      setfilteredSongs(songs)
    }

  }

  const AddSong = (data) => {
    const foundSong = addedSongs.filter((e) => e.song.id == data.id);

    if (foundSong.length > 0) return;
    else {
      setaddedSongs([...addedSongs, { id: addedSongs.length + 1, song: data }]);
      setfilteredSongs(filteredSongs.filter(e => e.id != data.id))
      console.log(filteredSongs, data.id);
    }
    // console.log("[...addedSongs, { id: addedSongs.length + 1, song: data }]");
    // console.log([...addedSongs, { id: addedSongs.length + 1, song: data }]);
  };

  const contentDown = (i, arr, setArr, name) => {
    const temp = arr;

    // console.log(temp);

    let index1Value = temp[i];
    let index2Value = temp[i + 1];
    let index1id = temp[i].id;
    let index2id = temp[i + 1].id;

    temp[i] = index2Value;
    temp[i + 1] = index1Value;

    temp[i].id = index1id;
    temp[i + 1].id = index2id;

    console.log(temp);
    setArr(temp);

    if (i == temp.length - 2) {
      window.document.getElementById(`${name}Top${temp.length - 1}`).focus();
    } else {
      window.document.getElementById(`${name}Down${i + 1}`).focus();
    }

    forceUpdate();
  };

  const contentUp = (i, arr, setArr, name) => {
    const temp = arr;

    // console.log(temp);

    let index1Value = temp[i];
    let index2Value = temp[i - 1];
    let index1id = temp[i].id;
    let index2id = temp[i - 1].id;

    temp[i] = index2Value;
    temp[i - 1] = index1Value;
    temp[i].id = index1id;
    temp[i - 1].id = index2id;

    console.log(temp);
    setArr(temp);

    if (i == 1) {
      window.document.getElementById(`${name}Down0`).focus();
    } else {
      window.document.getElementById(`${name}Top${i - 1}`).focus();
    }

    forceUpdate();
  };

  const contentDelete = (i, arr, setArr) => {
    const temp = arr;
    console.log("temp[i]", temp[i]);
    setfilteredSongs([...filteredSongs, temp[i].song])
    temp.splice(i, 1);

    setArr(temp);
    forceUpdate();
  };

  const submitData = async (e) => {
    e.preventDefault();

    showLoader()

    const __songs = addedSongs.map((item, i) => {
      return { id: item.id, _id: item.song.id, title: item.song.title, slug: item.slug }
    })

    // Link and unlink songs with album
    console.log(__songs, 'song array changed');
    await LinkSongWithAlbum(albumID, __songs)

    // updating album data
    const id = albumID;
    const form = new FormData();
    form.append("slug", id);
    form.append("_id", _id);
    form.append("title", Title);
    form.append("img", imgName.name);
    form.append("myfile", Artwork);
    form.append("youtube", StreamingLinks.youtube);
    form.append("spotify", StreamingLinks.spotify);
    form.append("appleMusic", StreamingLinks["apple-music"]);
    form.append("tidal", StreamingLinks.tidal);
    form.append("amazonMusic", StreamingLinks["amazon-music"]);
    form.append("soundCloud", StreamingLinks.soundcloud);
    form.append("songs", JSON.stringify(__songs.map(e => e._id)))

    const res = await updateAlbum(form);
    console.log(res);

    toastAlert(res.message, res.success ? "success" : "error")
    if (res.success) {
      navigate(`/admin/edit-album/${res.newSlug.slug}`)
      await getSongsAndAlbums()
    }
    hideLoader();
    scrollToElement("releases-albums-content", id, "Album element not found!");
  };

  const getThisAlbumData = async () => {

    showLoader()

    const id = albumID;
    const res = await getAlbumData(id)
    const data = res.message;

    if (data == null) return
    setTitle(data.title);

    set_id(data._id)

    const ___song = (data.songs).map((e, i) => ({ id: i + 1, song: { id: e._id, title: e.title, slug: e.slug } }))
    console.log("data.songs")
    console.log(___song)
    setaddedSongs(___song)


    setImgName({ name: data.img });
    setArtwork({ name: data.img });
    setStreamingLinks({
      youtube: data.youtube,
      spotify: data.spotify,
      "apple-music": data.appleMusic,
      tidal: data.tidal,
      "amazon-music": data.amazonMusic,
      soundcloud: data.soundCloud,
    });


    const songsList = await GetAllSongs()
    let songsData = (songsList.message).map(e => {
      return { id: e._id, title: e.title }
    })

    // finding unmatched data
    songsData = songsData.filter(item1 => !___song.some(item2 => item2.song.id === item1.id));
    setsongs(songsData)
    setfilteredSongs(songsData)


    hideLoader()

  }

  const deleteConfirm = async () => {

    const id = { _id: albumID }
    const res = await DeleteAlbum(id)
    console.log(res, 'isahdiuagdugasuydgasduyasgduyasgduysag')
    navigate('/admin/add-new')
    getSongsAndAlbums()
  }


  useEffect(() => {
    getThisAlbumData()

  }, [albumID])




  return (
    <>
      <DashHead />

      <div className="container-admin">
        <div className="row mb-5 ">
          <div className="col-4 position-relative">
            <Sidebar />
          </div>
          <div className="col-8">
            <h2>Edit Album / Project</h2>
            <form onSubmit={(e) => submitData(e)} className="d-grid gap-5 my-3" encType="multipart/form-data" >
              <section className="d-grid gap-3">
                <div className="d-flex justify-content-between">
                  <button disabled={Title == 'Singles'} type="button" className="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#deleteASongModal"> Delete Album </button>
                  <div className="d-flex gap-3 justify-content-end">
                    <Link to={"/admin/add-new"} aria-label="Cancel" className="btn btn-secondary" > Cancel </Link>
                    <button className="btn btn-primary" aria-label="Save"> Save </button>
                  </div>
                </div>

                <div className="form-floating">
                  <input type="text" disabled={Title == 'Singles'} className="form-control" aria-label="Add Album Title" id="title" name="title" value={Title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
                  <label htmlFor="title">Title</label>
                </div>

                <div class="input-group overflow-hidden">
                  <input type="file" className="form-control" aria-label="Upload Album Artwork" id="artworkPhoto" onChange={(e) => setArtwork(e.target.files[0])} />

                  <ImageComp myfile={Artwork} setmyfile={setArtwork} setFileName={setImgName} text={'Upload your Artwork'} forr={'artworkPhoto'} />

                </div>
              </section>

              <section className="d-grid gap-3">
                <h3>Streaming Links</h3>

                <div id="Streaming Links" className="ps-4">
                  {socialLinks.map((e, i) => {
                    return (
                      <div key={i} className={`form-floating form-social mb-3 social-icon-${e.icon}`} >
                        <input type="text" class="form-control" aria-label={`Add ${e.label} Link`} id={e.icon} value={StreamingLinks?.[e.icon]} onChange={(E) => setStreamingLinks({ ...StreamingLinks, [e.icon]: E.target.value, })} placeholder={e.label} />
                        <label for={e.icon}>{e.label}</label>
                      </div>
                    );
                  })}
                </div>
              </section>

              <section className="d-grid gap-3">
                <h3>Songs Linked</h3>

                <div id="songs_Linked" className="ps-3 d-grid gap-3">
                  <div className="d-grid">
                    <button type="button" className="btn btn-primary" aria-label={`Link A Song`} data-bs-toggle="modal" data-bs-target="#linkASongModal" > Link a Song </button>
                  </div>
                  <ul className="d-grid gap-3">
                    {addedSongs.map((data, i) => {
                      return (
                        <li key={i} className="d-flex justify-content-between rounded shadow px-3" >
                          <div className="d-flex align-items-center gap-3">
                            <button disabled={Title == 'Singles'} type="button" className="icon-btn icon-btn-danger"
                              aria-label={`Delete Song ${i + 1} ${data.song.title}`}
                              onClick={() => contentDelete(i, addedSongs, setaddedSongs)}
                            >
                              <span className="icon-delete"></span>
                            </button>
                            <div className="fs-4 d-flex align-items-center gap-2"><div>{i + 1}.</div><div>{data.song.title}</div></div>
                          </div>

                          <div className="d-flex align-items-center flex-shrink-1">
                            <div className="order-controls d-flex flex-column ">
                              <button
                                type="button"
                                id={`songTop${i}`}
                                className={`icon-btn icon-btn-primary`}
                                disabled={i == addedSongs.length - addedSongs.length ? true : false}
                                aria-label={`Move Up Song - ${i + 1} ${data.song}`}
                                onClick={i == addedSongs.length - addedSongs.length ? () => { return; } : () => contentUp(i, addedSongs, setaddedSongs, "song")}
                              >
                                <span className="icon-top-arrow"></span>
                              </button>

                              <button
                                type="button"
                                id={`songDown${i}`}
                                className={`icon-btn icon-btn-primary`}
                                disabled={i == addedSongs.length - 1 ? true : false}
                                aria-label={`Move Down Song - ${i + 1} ${data.song}`}
                                onClick={i == addedSongs.length - 1 ? () => { return; } : () => contentDown(i, addedSongs, setaddedSongs, "song")}
                              >
                                <span className="icon-down-arrow"></span>
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>

      <div class="modal fade" id="linkASongModal" tabindex="-1" aria-labelledby="linkASongModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title" id="linkASongModalLabel"> Link a Song </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body">
              <div className="d-grid gap-3">
                <a href={"/admin/add-new-song"}>Add a new song </a>


                <form class="d-flex" role="search">
                  <div class="input-group input-group-search">
                    <div class="form-floating">
                      <input id="linkASongSearch" type="text" className="form-control" placeholder="Filter by song name" onChange={(e) => filterSong(e.target.value)} />
                      <label htmlFor="linkASongSearch">Filter by song name</label>
                    </div>
                  </div>
                </form>



                {filteredSongs.length > 0 ?

                  <div className="d-grid gap-3 scroll-480">
                    {filteredSongs.map((data, i) => {
                      return (
                        <div key={i} className="d-flex p-3 justify-content-between align-items-center rounded shadow-sm" >
                          <span className="">{data.title}</span>
                          <button class="btn btn-primary" type="submit" onClick={() => AddSong(data)}>Link</button>
                        </div>
                      );
                    })}
                  </div>
                  
                  :
                  <p>All available songs are linked, no more songs to link</p>
                }



              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="modal fade" id="deleteASongModal" tabindex="-1" aria-labelledby="deleteASongModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title" id="deleteASongModalLabel"> Confirm Delete </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body">
              <div className="d-grid gap-3">
                <p>Are you sure you want to permanently delete the album “{Title}”?
                  Your associated songs with the album will not be deleted.
                  <br /> <br />
                  This can’t be undone!</p>
              </div>
            </div>
            <div class="modal-footer gap-2">
              <button to={"/admin/add-new"} data-bs-dismiss="modal" aria-label="No" className="btn btn-secondary" > No </button>
              <button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Yes" onClick={() => deleteConfirm()}> Yes </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default EditAlbum;