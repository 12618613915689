import React, { useEffect } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import { useState } from 'react'


import { useToast } from '../../ContextAPI/Components/notify';
import { completeProfile } from '../../ContextAPI/APIs/api';
import { useAuth } from '../../ContextAPI/Components/auth';
import ImageComp from '../../components/dashboard-components/ImageComp';
import { useLoader } from '../../ContextAPI/Components/loaderContext';


const AdminDashboard = () => {
    const { showLoader, hideLoader } = useLoader();
    const { user, getLoginUser } = useAuth();
    const { toastAlert } = useToast()

    const [firstName, setfirstName] = useState(null)
    const [lastName, setlastName] = useState(null)
    const [artistName, setartistName] = useState(null)
    const [username, setusername] = useState(null)
    const [description, setdescription] = useState(null)
    const [googleTagManagerId, setgoogleTagManagerId] = useState("")
    const [googleAnalyticsId, setgoogleAnalyticsId] = useState("")
    const [metaPixelId, setmetaPixelId] = useState("")
    const [fileName, setFileName] = useState(null)
    const [myfile, setmyfile] = useState(null)
    const [logoName, setLogoName] = useState(null)
    const [myLogo, setMyLogo] = useState(null)



    const submit = async (e) => {
        e.preventDefault();
        showLoader()
        if ((googleAnalyticsId && !googleTagManagerId)) {
            toastAlert("Google Tag Manager ID is required with Google Analytics ID")
            return hideLoader()
        } else if ((!googleAnalyticsId && googleTagManagerId)) {
            toastAlert("Google Analytics ID is required with Google Tag Manager ID")
            return hideLoader()
        }

        const myData = new FormData();
        myData.append('firstname', firstName);
        myData.append('lastname', lastName);
        myData.append('artistname', artistName);
        myData.append('description', description);
        myData.append('img', fileName.name != undefined ? fileName.name : '');
        myData.append('myfile', myfile);
        myData.append('logo', logoName != undefined ? logoName : '');
        myData.append('myLogo', myLogo);
        myData.append('googleTagManagerId', googleTagManagerId);
        myData.append('googleAnalyticsId', googleAnalyticsId);
        myData.append('metaPixelId', metaPixelId);

        console.log(myData);

        const res = await completeProfile(myData)

        toastAlert(res.message, res.success ? "success" : "error")
        getLoginUser()

        hideLoader()
    }

    useEffect(() => {
        if (user === null) {
            return
        }
        else {
            setfirstName(user.firstname);
            setlastName(user.lastname);
            setartistName(user.artistname)
            setFileName({ name: user.img })
            setmyfile({ name: user.img })
            setLogoName({ name: user.logo })
            setMyLogo({ name: user.logo })
            setusername(user?.username)
            setdescription(user?.description)
            setgoogleAnalyticsId(user?.googleAnalyticsId)
            setgoogleTagManagerId(user?.googleTagManagerId)
            setmetaPixelId(user?.metaPixelId)
        }
    }, [user])


    return <>
        <section>
            <DashHead />

            <div className="container-admin">


                <div className="row mb-5">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2 className=''>Name and Photo</h2>
                        <form className="d-grid gap-3 my-3" onSubmit={(e) => submit(e)} action="">
                            <div className="form-floating">
                                <input type="text" className='form-control' name="fname" id="fname" placeholder='First Name' value={firstName} onChange={(e) => setfirstName(e.target.value)} required />
                                <label htmlFor="fname">First Name</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className='form-control' name="lname" id="lname" placeholder='Last Name' value={lastName} onChange={(e) => setlastName(e.target.value)} required />
                                <label htmlFor="lname">Last Name</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className='form-control' name="artistName" id="artistName" placeholder='Your Artist Name' value={artistName} onChange={(e) => setartistName(e.target.value)} required />
                                <label htmlFor="artistName">Your Artist Name</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" disabled className='form-control' name="username" id="username" placeholder='Your Artist Name' value={username} onChange={(e) => setusername(e.target.value)} required />
                                <label htmlFor="username">Username</label>
                            </div>
                            <div class="form-floating">
                                <textarea class="form-control" name="description" id="description" placeholder='Your music and brand description' maxLength={200} style={{ height: "150px" }} value={description} onChange={(e) => setdescription(e.target.value)} required></textarea>
                                <label htmlFor="description"> Describe your music and brand </label>
                            </div>
                            <div class="form-floating">
                                <input class="form-control" name="metaPixelId" id="metaPixelId" placeholder='Your Meta Pixel ID' value={metaPixelId} onChange={(e) => setmetaPixelId(e.target.value)} />
                                <label htmlFor="metaPixelId"> Your Meta Pixel ID </label>
                            </div>
                            <div class="form-floating">
                                <input class="form-control" name="googleTagManagerId" id="googleTagManagerId" placeholder='Your Google Tag Manager ID' value={googleTagManagerId} onChange={(e) => setgoogleTagManagerId(e.target.value)} />
                                <label htmlFor="googleTagManagerId"> Your Google Tag Manager ID </label>
                            </div>
                            <div class="form-floating">
                                <input class="form-control" name="googleAnalyticsId" id="googleAnalyticsId" placeholder='Your Google Analytics ID' value={googleAnalyticsId} onChange={(e) => setgoogleAnalyticsId(e.target.value)} />
                                <label htmlFor="googleAnalyticsId"> Your Google Analytics ID </label>
                            </div>
                            <div class="input-group overflow-hidden">
                                <input type="file" className="form-control" id="profilePhoto" onChange={(e) => setmyfile(e.target.files[0])} />
                                <ImageComp myfile={myfile} setmyfile={setmyfile} setFileName={setFileName} forr={'profilePhoto'} text={'Upload your profile photo (optional)'} />
                            </div>
                            <div class="input-group overflow-hidden">
                                <input type="file" className="form-control" id="yourLogo" onChange={(e) => setMyLogo(e.target.files[0])} />
                                <ImageComp myfile={myLogo} setmyfile={setMyLogo} setFileName={setLogoName} forr={'yourLogo'} text={'Upload your logo (optional)'} />
                            </div>

                            <button className='btn btn-primary'>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default AdminDashboard
