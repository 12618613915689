import { createContext, useContext, useState } from "react"


// Step 1
const LoaderContext = createContext()

// Step 2
export const useLoader = () => {
    return useContext(LoaderContext);
}

// Step 3
export const LoaderProvider = ({ children }) => {

    const [loader, setloader] = useState(false);

    const showLoader = () => {
        setloader(true)
    }

    const hideLoader = () => {
        setloader(false)
    }

    return (
        <LoaderContext.Provider value={{ loader, showLoader, hideLoader }}>
            {children}
        </LoaderContext.Provider>
    )
}