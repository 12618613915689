import React from 'react'

const Loader = () => {
    return (
        <div className='position-fixed z-1080 top-0 left-0 h-100 w-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50'>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Loader
