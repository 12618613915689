import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CombineSongVideos, GetVideosOfSong, UpdateSongVideo, deleteVideo, GetAllVideosAuthor, GetAllVideosType } from "../../../ContextAPI/APIs/api";
import { useToast } from "../../../ContextAPI/Components/notify"
import { useLoader } from "../../../ContextAPI/Components/loaderContext";
import TypeAheadLoops from "../../../components/TypeAheadLoops";




const Video = () => {
    const { songID } = useParams();
    const navigate = useNavigate();
    const { hash, pathname } = useLocation();
    const { toastAlert } = useToast();
    const { loader, showLoader, hideLoader } = useLoader()
    const [deletedItem, setDeletedItem] = useState([])

    if ((pathname == '/admin/edit-song/' || pathname == '/admin/edit-song') && (!songID)) {
        toastAlert('Invalid Request', "error");
        navigate('/admin/add-new')
    }

    const [, forceUpdate] = useReducer((x) => x + 1, 0);


    const typeAheadData = [{ videoType: 'Lyrical' }, { videoType: 'Instrumental' }, { videoType: 'Interview' }, { videoType: 'Breaking Down The Beat' }]

    const videosTemplate = {
        id: 1,
        videoType: "Lyrical",
        videoAuthor: "",
        videoYoutube: "",
        file: '',
        img: { name: '' },
        podcastToggle: false,
        podcastYoutube: '',
        podcastSpotify: '',
        podcastItunes: '',
        podcastAmazonMusic: '',
    };

    const [videosID, setvideosID] = useState([]);
    const [videosData, setvideosData] = useState([videosTemplate]);
    const [AuthorsList, setAuthorsList] = useState([]);
    const [TypeList, setTypeList] = useState([]);


    // VALUE HANDLER FUNCIONS
    const setToggle = (id, value) => {

        const nextLayout = videosData.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    podcastToggle: value,
                };
            } else {
                return item;
            }
        });

        // Re-render with the new array
        setvideosData(nextLayout);
    }

    const videosTypeValueHandler = (e, i) => {
        const updatedData = videosData.map((item, index) => {
            if (index === i) {
                return { ...item, videoType: e };
            } else {
                return item;
            }
        });

        setvideosData(updatedData);
        console.log(updatedData);
    };

    const videosAuthorValueHandler = (e, i) => {
        const updatedData = videosData.map((item, index) => {
            if (index === i) {
                return { ...item, videoAuthor: e };

            } else {
                return item;

            }
        });

        setvideosData(updatedData);
    };

    const videosYoutubeValueHandler = (e, i) => {
        const updatedData = videosData.map((item, index) => {
            if (index === i) {
                return { ...item, videoYoutube: e.target.value };

            } else {
                return item;

            }
        });

        setvideosData(updatedData);
    };

    const videosThumbnailValueHandler = (e, i) => {
        const updatedData = videosData.map((item, index) => {
            if (index === i) {
                return { ...item, file: e, img: { name: `${e ? e.name : ''}` } };

            } else {
                return item;

            }
        });

        console.log(updatedData);
        setvideosData(updatedData);
    };

    const videosPodcastValueHander = (e, i) => {
        const updatedData = videosData.map((item, index) => {
            if (index === i) {
                return { ...item, [e.target.name]: e.target.value };

            } else {
                return item;

            }
        });

        setvideosData(updatedData);
    }


    // LAYOUT CHANGE FUNCTIONS
    const contentDown = (i, arr, setArr, name) => {
        const temp = arr;

        // console.log(temp);

        let index1Value = temp[i];
        let index2Value = temp[i + 1];
        let index1id = temp[i].id;
        let index2id = temp[i + 1].id;

        temp[i] = index2Value;
        temp[i + 1] = index1Value;

        temp[i].id = index1id;
        temp[i + 1].id = index2id;

        console.log(temp);
        setArr(temp);

        if (i == temp.length - 2) {
            window.document.getElementById(`${name}Top${temp.length - 1}`).focus();
        } else {
            window.document.getElementById(`${name}Down${i + 1}`).focus();
        }

        forceUpdate();
    };

    const contentUp = (i, arr, setArr, name) => {
        const temp = arr;

        // console.log(temp);

        let index1Value = temp[i];
        let index2Value = temp[i - 1];
        let index1id = temp[i].id;
        let index2id = temp[i - 1].id;

        temp[i] = index2Value;
        temp[i - 1] = index1Value;
        temp[i].id = index1id;
        temp[i - 1].id = index2id;

        console.log(temp);
        setArr(temp);

        if (i == 1) {
            window.document.getElementById(`${name}Down0`).focus();
        } else {
            window.document.getElementById(`${name}Top${i - 1}`).focus();
        }

        forceUpdate();
    };

    const contentDelete = async (i, arr, setArr, videoID) => {
        if (typeof videoID != "undefined" && videoID != null) {
            // toastAlert(res.message, res.success ? "success" : "error");
            setDeletedItem([...deletedItem, videoID])
        }

        const temp = arr;
        temp.splice(i, 1);

        arr.forEach((e, i) => {
            e.id = i + 1
        });

        console.log(arr);

        setArr(temp);
        forceUpdate();


        console.log(arr);

    };

    const updateVideos = async (e) => {
        e.preventDefault();
        console.log(videosData);

        console.log("deletedItem -> ", deletedItem);
        if (deletedItem.length > 0) {
            const res = await deleteVideo({ videoID: deletedItem })
            setDeletedItem([])
        }


        // showLoader()

        for (const item of videosData) {
            const myForm = new FormData()
            console.log("Video submit", item);
            myForm.append("song", songID)
            myForm.append(`id`, item.id);
            myForm.append(`videoAuthor`, item.videoAuthor);
            myForm.append(`img`, item.img.name || "");
            myForm.append(`myfile`, item.file);
            myForm.append(`videoType`, item.videoType);
            myForm.append(`videoYoutube`, item.videoYoutube);

            if (typeof item._id != "undefined" && item._id != null && item._id != "") {
                myForm.append(`videoID`, item._id);
            }
            myForm.append(`podcastToggle`, item.podcastToggle || false)
            if (typeof item.podcastYoutube != "undefined" && item.podcastYoutube != null) myForm.append(`podcastYoutube`, item.podcastYoutube);
            if (typeof item.podcastSpotify != "undefined" && item.podcastSpotify != null) myForm.append(`podcastSpotify`, item.podcastSpotify);
            if (typeof item.podcastItunes != "undefined" && item.podcastItunes != null) myForm.append(`podcastItunes`, item.podcastItunes);
            if (typeof item.podcastAmazonMusic != "undefined" && item.podcastAmazonMusic != null) myForm.append(`podcastAmazonMusic`, item.podcastAmazonMusic);

            await UpdateSongVideo(myForm)
                .then(res => {
                    console.log(res);
                    if (res.success) toastAlert(res.message, res.success ? "success" : "error");
                })

        }


        await getVideos()

        // toastAlert(res.success ? "Lyrics updated" : "Error in updating Lyrics", res.success ? "success" : "error");

        // hideLoader()
    }

    async function getAllVideosAuthor() {
        const res = await GetAllVideosAuthor()
        if (res.success) {
            const d = getUniqueArtists(res.message, "videoAuthor")
            setAuthorsList(d)
        }
    }

    async function getAllVideosType() {
        const res = await GetAllVideosType()
        if (res.success) {
            console.log(res.message, 'abcdefghijk');
            // const list = uniqueNamesArray(typeAheadData, res.message)
            const list = getUniqueArtists([...typeAheadData, ...res.message], "videoType")
            console.log(list);
            setTypeList(list)
        }
    }

    async function getVideos() {
        const res = await GetVideosOfSong(songID)
        console.log("res res res res res");
        console.log(res);

        if (res.success) {
            let ids = []
            let temp = []
            for (const item of (res.data)) {
                temp.push({
                    id: item.id,
                    videoType: item.videoType,
                    videoAuthor: item.videoAuthor,
                    videoYoutube: item.videoYoutube,
                    img: { name: item.img },
                    file: "",
                    song: item.song || "",
                    _id: item._id || "",
                    podcastYoutube: item?.podcastYoutube || "",
                    podcastSpotify: item?.podcastSpotify || "",
                    podcastItunes: item?.podcastItunes || "",
                    podcastAmazonMusic: item?.podcastAmazonMusic || "",
                    podcastToggle: item?.podcastToggle || false,
                })
                ids.push(item._id)
            }
            setvideosID(ids)
            setvideosData(temp)
        } else {
            setvideosData([videosTemplate])
        }
    }

    function uniqueNamesArray(arr1, arr2) {
        // Concatenate the two arrays
        const combinedArray = arr1.concat(arr2);

        // Use a Set to automatically filter out duplicates
        const uniqueNamesSet = new Set(combinedArray);

        // Convert the Set back to an array
        const uniqueNamesArray = Array.from(uniqueNamesSet);

        return uniqueNamesArray;
    }
    function getUniqueArtists(artists, type) {
        // Create a Set to store unique names
        const uniqueNames = new Set();

        // Filter out duplicates and convert names to lowercase for case-insensitivity
        console.log("videoNames", artists);
        const uniqueArtists = artists.filter(artist => {

            console.log(artist, 'dadadadadada');
            // const lowercaseName = artist.hasOwnProperty(type) ? artist[type] : artist
            const lowercaseName = artist[type]
            if (!uniqueNames.has(lowercaseName)) {
                uniqueNames.add(lowercaseName);
                return true;
            }
            return false;
        });

        return uniqueArtists.sort((a, b) => {
            // Use the localeCompare method for string comparison
            return a[type].localeCompare(b[type]);
        });
    }

    const handleCancel = () => {

        document.getElementById('artist-content-accordion-btn').classList.add('collapsed')

        getAllVideosAuthor()
        getAllVideosType()
        getVideos()
    }

    useMemo(() => {
        getAllVideosAuthor()
        getAllVideosType()
        getVideos()

    }, [songID])

    useEffect(() => {
        console.log(deletedItem);
    }, [deletedItem])


    const namesList = ["ali", "Ahmed", 'Alice', 'Bob', 'Charlie', 'David', 'Eva', 'Frank'];
    const namesList2 = [{
        id: 1, name: "ali",
    }, {
        id: 2, name: "ahmed",
    }, {
        id: 3, name: 'Alice',
    }, {
        id: 4, name: 'Bob',
    }, {
        id: 5, name: 'Charlie',
    }]

    const handleValueChange = (value) => {
        console.log(value);
    };

    return <>
        <form onSubmit={(e) => updateVideos(e)}>
            <section className="accordion-item d-grid shadow rounded-3">
                <fieldset>
                    <legend className="accordion-header accordion-sticky-header sticky-top rounded-3">
                        <h3>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#videos-links-content" aria-expanded="true" aria-controls="videos-links-content"> Videos </button>
                        </h3>
                    </legend>
                    <div id="videos-links-content" class="accordion-collapse collapse" data-bs-parent="#accordionAdminSettings" >
                        <div class="accordion-body">
                            <div id="videos" className="d-grid gap-3 px-3">
                                <div className="d-flex gap-3 justify-content-end py-3 border-top sticky-top accordion-sticky-actions">
                                    <button type="button" aria-label="Cancel" className="btn btn-secondary" onClick={handleCancel} data-bs-toggle="collapse" data-bs-target="#videos-links-content" aria-expanded="true" aria-controls="videos-links-content"> Cancel </button>
                                    <button type="submit" className="btn btn-primary"> Save and Continue</button>
                                </div>
                                {videosData.map((data, i) => {
                                    return (
                                        <div key={i} id="video-div">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4>Video {i + 1}</h4>
                                                <div>
                                                    <button type="button" className="icon-btn icon-btn-danger" aria-label={`Delete Video ${i + 1}`} onClick={() => contentDelete(i, videosData, setvideosData, data?._id || null)} >
                                                        <span className={`icon-delete text-danger`} ></span>
                                                    </button>
                                                    <button type="button" id={`videoTop${i}`} className={`icon-btn icon-btn-primary`} disabled={i == videosData.length - videosData.length ? true : false} aria-label={`Move Up Video ${i + 1}`} onClick={i == videosData.length - videosData.length ? () => { return; } : () => contentUp(i, videosData, setvideosData, "video")} >
                                                        <span className={`icon-top-arrow`}></span>
                                                    </button>
                                                    <button type="button" id={`videoDown${i}`} className={`icon-btn icon-btn-primary`} disabled={i == videosData.length - 1 ? true : false} aria-label={`Move Down Video ${i + 1}`} onClick={i == videosData.length - 1 ? () => { return; } : () => contentDown(i, videosData, setvideosData, "video")} > <span className={`icon-down-arrow`} ></span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="d-grid gap-3">
                                                <TypeAheadLoops
                                                    list={TypeList}
                                                    stateData={data}
                                                    valueKey={'videoType'}
                                                    updateStateFunction={videosTypeValueHandler}
                                                    index={i}
                                                    attrValue={`videoType${i + 1}`}
                                                    label={"Type"}
                                                    ariaLabel={`Add Video ${i + 1} Type`}
                                                    suggestionDivId={`suggestionDivVideoType${i + 1}`}
                                                    valueProp="videoType"
                                                    keyProp="videoType"
                                                />


                                                <TypeAheadLoops
                                                    list={AuthorsList}
                                                    stateData={data}
                                                    valueKey={'videoAuthor'}
                                                    updateStateFunction={videosAuthorValueHandler}
                                                    index={i}
                                                    attrValue={`videoAuthor${i + 1}`}
                                                    label={"Author"}
                                                    ariaLabel={`Add Video ${i + 1} Author`}
                                                    suggestionDivId={`suggestionDivVideoAuthor${i + 1}`}
                                                    valueProp="videoAuthor"
                                                    keyProp="videoAuthor"
                                                />


                                                {/* <div class="form-floating">
                                                    <input type="text" className="form-control" aria-label={`Add Video ${i + 1} Author`} id={`videoAuthor${i + 1}`} name={`videoAuthor${i + 1}`} placeholder="Author" value={data.videoAuthor} onChange={(e) => videosAuthorValueHandler(e, i)} required />
                                                    <label for={`videoAuthor${i + 1}`}> Author </label>
                                                </div> */}

                                                <div className={`form-floating form-social social-icon-youtube`} >
                                                    <input type="text" class="form-control" aria-label={`Add Video ${i + 1} Youtube Link`} id={`YoutubeLinks${i + 1}`} name={`YoutubeLinks${i + 1}`} value={data.videoYoutube} onChange={(e) => videosYoutubeValueHandler(e, i)} placeholder="Youtube" required />
                                                    <label for={`YoutubeLink${i + 1}`}> Youtube </label>
                                                </div>

                                                <div class="input-group">
                                                    <input type="file" className="form-control" aria-label={`Add Video ${i + 1} Thumbnail`} id={`youtubeThumbnail${i + 1}`} onChange={(e) => videosThumbnailValueHandler(e.target.files[0], i)} />

                                                    {
                                                        ((data.img.name) && (typeof data.img.name != "undefined") && (data.img.name != '') && (data.img.name != null)) ?
                                                            <>
                                                                <div className="input-group-text">
                                                                    <span className='icon-photo'></span>
                                                                    <span className='icon-check-circle text-success'></span>
                                                                    <button type="button" className='btn btn-link-danger p-0' onClick={() => videosThumbnailValueHandler('', i)}>Remove "{data.img.name}"</button>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <label className="input-group-text" for={`youtubeThumbnail${i + 1}`}>
                                                                    <span className='icon-photo'></span>
                                                                    <span className='icon-check-circle text-success'></span>
                                                                    {"Upload Video Thumbnail Photo"}
                                                                </label>
                                                            </>
                                                    }

                                                </div>


                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" checked={data.podcastToggle} role="switch" id={`video${i + 1}podcastSwitch`} name={`video${i + 1}podcastSwitch`} onChange={(E) => setToggle(data.id, E.target.checked)} />
                                                    <label class="form-check-label" for={`video${i + 1}podcastSwitch`}>Podcast Episode?</label>
                                                </div>

                                                {

                                                    data.podcastToggle && <>

                                                        <div className="d-grid gap-3 ps-3">
                                                            <h4>Podcast Episode Links</h4>

                                                            <div className={`form-floating form-social social-icon-youtube`} >
                                                                <input type="text" class="form-control" aria-label={`Add Youtube Podcast Link`} id={`video${i + 1}podcastYoutube`} name={`podcastYoutube`} value={data.podcastYoutube} onChange={(E) => videosPodcastValueHander(E, i)} placeholder={'https://'} />
                                                                <label for={`video${i + 1}podcastYoutube`}>{'Youtube Podcast'}</label>
                                                            </div>
                                                            <div className={`form-floating form-social social-icon-spotify`} >
                                                                <input type="text" class="form-control" aria-label={`Add Spotify Podcast Link`} id={`video${i + 1}podcastSpotify`} name={`podcastSpotify`} value={data.podcastSpotify} onChange={(E) => videosPodcastValueHander(E, i)} placeholder={'https://'} />
                                                                <label for={`video${i + 1}podcastSpotify`}>{'Spotify Podcast'}</label>
                                                            </div>
                                                            <div className={`form-floating form-social social-icon-apple-podcast`} >
                                                                <input type="text" class="form-control" aria-label={`Add Apple Podcast Link`} id={`video${i + 1}podcastApple`} name={`podcastItunes`} value={data.podcastItunes} onChange={(E) => videosPodcastValueHander(E, i)} placeholder={'https://'} />
                                                                <label for={`video${i + 1}podcastApple`}>{'Apple Podcast'}</label>
                                                            </div>
                                                            <div className={`form-floating form-social social-icon-amazon-music`} >
                                                                <input type="text" class="form-control" aria-label={`Add Amazon Music Link`} id={`video${i + 1}podcastAmazonMusic`} name={`podcastAmazonMusic`} value={data.podcastAmazonMusic} onChange={(E) => videosPodcastValueHander(E, i)} placeholder={'https://'} />
                                                                <label for={`video${i + 1}podcastAmazonMusic`}>{'Amazon Podcast'}</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="add-another-div">
                                    <h4>Add Another (Optional)</h4>
                                    <div className="d-grid">
                                        <button type="button" onClick={() => setvideosData([...videosData, { id: videosData.length + 1, videoType: "Lyrical", videoAuthor: "", videoYoutube: "", img: "", },])} aria-label={`Add Another Video Optional`} className="btn btn-secondary" > Add Another </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </section>
        </form>
    </>
}





// const Typeahead = ({ names }) => {
//     const [inputValue, setInputValue] = useState('');
//     const [filteredNames, setFilteredNames] = useState([]);
//     const [selectedName, setSelectedName] = useState(null);
//     const [isDropdownVisible, setDropdownVisible] = useState(false);

//     const inputRef = useRef(null);

//     useEffect(() => {
//         setFilteredNames(names);
//     }, [names]);

//     const handleInputChange = (e) => {
//         const value = e.target.value;
//         setInputValue(value);

//         // Filter names based on user input
//         const filtered = names.filter((name) =>
//             name.toLowerCase().includes((value.toLowerCase()).trim())
//         );

//         setFilteredNames(filtered);
//         setDropdownVisible(filtered.length > 0 ? true : false);
//     };

//     const handleKeyDown = (e) => {
//         switch (e.key) {
//             case 'ArrowDown':
//                 e.preventDefault();
//                 navigateDropdown(1);
//                 break;
//             case 'ArrowUp':
//                 e.preventDefault();
//                 navigateDropdown(-1);
//                 break;
//             case 'Enter':
//                 if (filteredNames.length == 0) { }
//                 else if (selectedName) {
//                     handleNameSelect(selectedName);
//                 } else if (filteredNames.length > 0) {
//                     handleNameSelect(filteredNames[0]);
//                 }
//                 break;
//             case 'Escape':
//                 if (inputValue === '' && selectedName) {
//                     setInputValue(selectedName);
//                 }
//                 setDropdownVisible(false);
//                 break;
//             default:
//                 break;
//         }
//     };

//     const handleNameSelect = (name) => {
//         setSelectedName(name);
//         setInputValue(name);
//         setDropdownVisible(false);
//         inputRef.current.focus();
//     };

//     const navigateDropdown = (step) => {
//         const currentIndex = filteredNames.indexOf(selectedName);
//         let newIndex = currentIndex + step;

//         if (newIndex < 0) {
//             newIndex = filteredNames.length - 1;
//         } else if (newIndex >= filteredNames.length) {
//             newIndex = 0;
//         }

//         setSelectedName(filteredNames[newIndex]);
//     };

//     const handleInputBlur = () => {
//         // Use setTimeout to wait for the potential click on the dropdown
//         setTimeout(() => {
//             setDropdownVisible(false);
//         }, 200);
//     };

//     return (
//         <div className="dropdown">
//             <input
//                 type="text"
//                 value={inputValue}
//                 onChange={handleInputChange}
//                 onKeyDown={handleKeyDown}
//                 onBlur={handleInputBlur}
//                 placeholder="Type a name..."
//                 ref={inputRef}
//                 className="form-control"
//                 aria-haspopup="listbox"
//                 aria-expanded={isDropdownVisible}
//                 role="combobox"
//                 aria-autocomplete="list"
//                 aria-owns="name-list"
//                 aria-activedescendant={selectedName}
//                 data-toggle="dropdown"
//             />

//             {isDropdownVisible && (
//                 <ul
//                     id="name-list"
//                     role="listbox"
//                     aria-labelledby="typeahead-label"
//                     className="dropdown-menu show musto-typeahead"
//                 >
//                     {filteredNames.map((name, index) => (
//                         <li
//                             key={index}
//                             role="option"
//                             aria-selected={name === selectedName}
//                             id={name}
//                             className={`dropdown-item ${name === selectedName ? 'active' : ''}`}
//                         >
//                             <button
//                                 type="button"
//                                 className="btn w-100 btn-secondary"
//                                 onClick={() => handleNameSelect(name)}
//                             >
//                                 {name}
//                             </button>
//                         </li>
//                     ))}
//                 </ul>
//             )}

//             {/* {selectedName && <p className="mt-2">Selected: {selectedName}</p>} */}
//         </div>
//     );
// };


// const ObjectTypeahead = ({ list, keyProp, valueProp, onValueChange }) => {
//     const [inputValue, setInputValue] = useState('');
//     const [filteredItems, setFilteredItems] = useState([]);
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [isDropdownVisible, setDropdownVisible] = useState(false);

//     const inputRef = useRef(null);

//     useEffect(() => {
//         setFilteredItems(list);
//     }, [list]);

//     const handleInputChange = (e) => {
//         const value = e.target.value;
//         setInputValue(value);

//         console.log(value);

//         // Filter items based on user input
//         const filtered = list.filter((item) => {
//             if (typeof item[keyProp] !== "undefined" && item[keyProp] !== null) {
//                 return item[keyProp].toLowerCase().includes(value.toLowerCase().trim())
//             }
//         });
//         console.log(filtered);

//         setFilteredItems(filtered);
//         setDropdownVisible(filtered.length > 0 ? true : false);

//         onValueChange(filtered[0] || { [valueProp]: null, [keyProp]: value });

//     };

//     const handleKeyDown = (e) => {
//         switch (e.key) {
//             case 'ArrowDown':
//                 e.preventDefault();
//                 navigateDropdown(1);
//                 break;
//             case 'ArrowUp':
//                 e.preventDefault();
//                 navigateDropdown(-1);
//                 break;
//             case 'Enter':
//                 if (filteredItems.length == 0) { }
//                 else if (selectedItem) {
//                     handleItemSelect(selectedItem);
//                     onValueChange(selectedItem);

//                 } else if (filteredItems.length > 0) {
//                     handleItemSelect(filteredItems[0]);
//                     onValueChange(filteredItems[0]);
//                 }
//                 break;
//             case 'Escape':
//                 if (inputValue === '' && selectedItem) {
//                     setInputValue(selectedItem[keyProp]);
//                     onValueChange(selectedItem);
//                 }
//                 setDropdownVisible(false);
//                 break;
//             default:
//                 break;
//         }
//     };

//     const handleItemSelect = (item) => {
//         setSelectedItem(item);
//         setInputValue(item[keyProp]);
//         setDropdownVisible(false);
//         inputRef.current.focus();

//         onValueChange(item);
//     };

//     const navigateDropdown = (step) => {
//         const currentIndex = filteredItems.indexOf(selectedItem);
//         let newIndex = currentIndex + step;

//         if (newIndex < 0) {
//             newIndex = filteredItems.length - 1;
//         } else if (newIndex >= filteredItems.length) {
//             newIndex = 0;
//         }

//         setSelectedItem(filteredItems[newIndex]);
//     };

//     const handleInputBlur = () => {
//         setTimeout(() => {
//             setDropdownVisible(false);
//         }, 200);
//     };

//     return (
//         <div className="dropdown">
//             <input
//                 type="text"
//                 value={inputValue}
//                 onChange={handleInputChange}
//                 onKeyDown={handleKeyDown}
//                 onBlur={handleInputBlur}
//                 placeholder={`Type a ${valueProp}...`}
//                 ref={inputRef}
//                 className="form-control"
//                 aria-haspopup="listbox"
//                 aria-expanded={isDropdownVisible}
//                 role="combobox"
//                 aria-autocomplete="list"
//                 aria-owns="item-list"
//                 aria-activedescendant={selectedItem ? selectedItem[keyProp] : null}
//                 data-toggle="dropdown"
//             />

//             {isDropdownVisible && (
//                 <ul
//                     id="item-list"
//                     role="listbox"
//                     aria-labelledby="typeahead-label"
//                     className="dropdown-menu show musto-typeahead"
//                 >
//                     {filteredItems.map((item, index) => (
//                         <li
//                             key={index}
//                             role="option"
//                             aria-selected={item === selectedItem}
//                             id={item[keyProp]}
//                             className={`dropdown-item ${item === selectedItem ? 'active' : ''}`}
//                         >
//                             <button
//                                 type="button"
//                                 className="btn w-100 btn-secondary"
//                                 onClick={() => handleItemSelect(item)}
//                             >
//                                 {item[keyProp]}
//                             </button>
//                         </li>
//                     ))}
//                 </ul>
//             )}

//             {selectedItem && (
//                 <p className="mt-2">
//                     Selected: {selectedItem[keyProp]} - {selectedItem[valueProp]}
//                 </p>
//             )}
//         </div>
//     );
// };



export default Video