
import logo from '../assets/svg/zintune-logo.svg'
import { signup } from '../ContextAPI/APIs/api';
import { useState } from 'react';
import { useToast } from '../ContextAPI/Components/notify';
import { Link, useNavigate } from 'react-router-dom';



const SignUp = () => {
    const { toastAlert } = useToast()
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')

    const navigate = useNavigate();

    const SignUp = async (e) => {
        e.preventDefault();

        if (password !== confPassword) return toastAlert("Whoops! Your password does not match", "error")

        const res = await signup({ email, username, password})

        if (res.success) {
            toastAlert("Woohoo! Your profile has been successully create!", "success")
            return navigate('/complete-profile/' + res.message)
        }
        toastAlert(res.message, res.success ? "success" : "error")
    }


    return <>
        <div className="skippy visually-hidden-focusable overflow-hidden">
            <div className="container">
                <a className="d-inline-flex p-2 m-1 link-primary" href="#email">Skip to sign up</a>
            </div>
        </div>

        <div className="container-form text-center py-5">

            <main>
                <section>

                    <Link to={'/sign-in'} className="d-block">
                        <img className="zintune-logo" src={logo} alt="Zin Tune" />
                    </Link>

                    <div id="admin-intro">
                        <span className="h3">Welcome to Zintunes</span>
                        <p>Interactive Platform to create your music journey</p>
                    </div>

                    <h1 aria-describedby="admin-intro">Sign Up</h1>

                    <form className="d-grid gap-3" onSubmit={(e) => SignUp(e)} >
                        <div className="form-floating">
                            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="email" placeholder="name@example.com" required />
                            <label htmlFor="email">Please enter your email</label>
                        </div>
                        <div className="form-floating">
                            <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} id="username" placeholder="YarleyG" required />
                            <label htmlFor="username">Username</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                            <label htmlFor="password">Please enter your password</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" id="password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} placeholder="Confirn password" required />
                            <label htmlFor="password">Confirm password</label>
                        </div>
                        <button className="btn btn-primary" type="submit">Sign Up</button>
                    </form>
                    <div className="d-grid">
                        <Link to={'/sign-in'} className="btn btn-link">Sign In</Link>
                    </div>

                </section>
            </main>
            <footer className="my-5">
                <p>© 2023 YNO Designs LLC</p>
            </footer>

        </div>


        {/* <section className='py-5'>
            <div className="container">
                <div className='row mx-0 mb-5 text-center d-flex justify-content-center'>
                    <div className="col-lg-5 col-md-7 col-12">
                        <div>
                            <img src={logo} alt="logo" />
                        </div>
                        <h3>Welcome to Zintunes</h3>
                        <p className=''>Interactive Platform to create your music journey</p>
                        <h1 className='mt-2'>Sign Up</h1>

                        <form className="row g-3 mt-4 mx-0" onSubmit={(e) => SignUp(e)}>
                            <div className='form-floating p-0'>
                                <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Please enter your email' required />
                                <label htmlhtmlFor="floatingInput">Please enter your email</label>
                            </div>
                            <div className='form-floating p-0'>
                                <input type="password" className='form-control' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Please enter your password' required />
                                <label htmlhtmlFor="floatingInput">Please enter your password</label>
                            </div>
                            <div className='form-floating p-0'>
                                <input type="password" className='form-control' value={confPassword} onChange={(e) => setConfPassword(e.target.value)} placeholder='Confirm password' required />
                                <label htmlhtmlFor="floatingInput">Confirm password</label>
                            </div>
                            <button className='btn btn-primary'>Sign Up</button>
                            <Link to={'/sign-in'} className='text-primary fw-bold my-4'> Sign In </Link>
                        </form>

                        <p className=''>© 2023 YNO Designs LLC</p>
                    </div>
                </div>
            </div>
        </section> */}
    </>
}

export default SignUp
