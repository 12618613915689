export function truncateAndFormatText(text, maxLength) {
    const fileExtensionMatch = text.match(/\.(png|jpg|jpeg|gif|bmp)$/i);
  
    if (!fileExtensionMatch) {
      return text;
    }
  
    const fileExtension = fileExtensionMatch[1];
    const truncatedText = text.substring(0, maxLength);
  
    return `${truncatedText}...${fileExtension}`;
  }


/**
 * 
 * 
 * // Example usage:
 * const exampleText1 = "Violet and White Simple Playlist YouTube Thumbnail (3).png";
 * const exampleText2 = "324234234234.png";
 * 
 * const truncatedFormattedText1 = truncateAndFormatText(exampleText1, 3);
 * const truncatedFormattedText2 = truncateAndFormatText(exampleText2, 3);
 * 
 * console.log(truncatedFormattedText1); // Output: Vio....png
 * console.log(truncatedFormattedText2); // Output: 324....png * 
 * 
 * 
**/
