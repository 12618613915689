import { useEffect, useReducer, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GetSongData, UpdateSong, getAllAlbum, linkCredits, getAllCreditorsName } from "../../../ContextAPI/APIs/api";
import { useToast } from "../../../ContextAPI/Components/notify"
import { useLoader } from "../../../ContextAPI/Components/loaderContext";
import TypeAheadLoops from "../../../components/TypeAheadLoops";
import { moveElementOnScreenTop } from "../../../helpers/util";
import { useCreditorsArtist } from "../../../ContextAPI/Components/CreditorsArstisState";




const Credits = ({ allCreditsData, data }) => {

  const { songID } = useParams();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const { toastAlert } = useToast();
  const { loader, showLoader, hideLoader } = useLoader()
  const { CreditorsArtistList, setCreditorsArtistList } = useCreditorsArtist()

  if ((pathname == '/admin/edit-song/' || pathname == '/admin/edit-song') && (!songID)) {
    toastAlert('Invalid Request', "error");
    navigate('/admin/add-new')
  }

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const creditsTemplate = {
    type: "lyrical",
    name: "",
  };

  const [creditsData, setcreditsData] = useState([creditsTemplate]);

  const creditsTypeValueHandler = (e, i) => {
    const updatedData = creditsData.map((item, index) => {
      if (index === i) {
        return { ...item, type: e.target.value };

      } else {
        return item;

      }
    });

    console.log(updatedData, 'updatedBhaiUpdated');

    setcreditsData(updatedData);
  };

  const creditsNameValueHandler = (e, i) => {
    const updatedData = creditsData.map((item, index) => {
      if (index === i) {
        return { ...item, name: e };

      } else {
        return item;

      }
    });

    setcreditsData(updatedData);
  };

  const contentDown = (i, arr, setArr, name) => {
    const temp = arr;

    // console.log(temp);

    let index1Value = temp[i];
    let index2Value = temp[i + 1];
    let index1id = temp[i].id;
    let index2id = temp[i + 1].id;

    temp[i] = index2Value;
    temp[i + 1] = index1Value;

    temp[i].id = index1id;
    temp[i + 1].id = index2id;

    console.log(temp);
    setArr(temp);

    if (i == temp.length - 2) {
      window.document.getElementById(`${name}Top${temp.length - 1}`).focus();
    } else {
      window.document.getElementById(`${name}Down${i + 1}`).focus();
    }

    forceUpdate();
  };

  const contentUp = (i, arr, setArr, name) => {
    const temp = arr;

    // console.log(temp);

    let index1Value = temp[i];
    let index2Value = temp[i - 1];
    let index1id = temp[i].id;
    let index2id = temp[i - 1].id;

    temp[i] = index2Value;
    temp[i - 1] = index1Value;
    temp[i].id = index1id;
    temp[i - 1].id = index2id;

    console.log(temp);
    setArr(temp);

    if (i == 1) {
      window.document.getElementById(`${name}Down0`).focus();
    } else {
      window.document.getElementById(`${name}Top${i - 1}`).focus();
    }

    forceUpdate();
  };

  const contentDelete = (i, arr, setArr) => {
    const temp = arr;

    temp.splice(i, 1);

    setArr(temp);

    forceUpdate();
  };


  const updateCreditsData = async (e) => {
    e.preventDefault()

    showLoader()

    if (typeof songID != "undefined" && songID != null && songID != "") {
      const res = await linkCredits(songID, creditsData);
      console.log(res);

      toastAlert(res.success ? res.message : res.message, res.success ? "success" : "error");

      GetAllCreditorsName()

      if (res.success) {
        window.document.getElementById(`lyrics-links-content`).classList.add("show");
        window.document.getElementById(`credits-links-content`).classList.remove("show");
        window.document.getElementById(`lyricsType1`).focus();
        moveElementOnScreenTop("#accordionAdminSettings > form:nth-child(5) > section");
      }
    }

    hideLoader()

  }

  const GetAllCreditorsName = async () => {
    const res = await getAllCreditorsName()
    if (res.success) {

      function getUniqueArtists(credits) {
        // Create a Set to store unique names
        const uniqueNames = new Set();

        // Filter out duplicates and convert names to lowercase for case-insensitivity
        const uniqueArtists = credits.filter(credit => {
          const lowercaseName = credit.name.toLowerCase();
          if (!uniqueNames.has(lowercaseName)) {
            uniqueNames.add(lowercaseName);
            return true;
          }
          return false;
        });

        return uniqueArtists;
      }


      var unique = getUniqueArtists(res.message);
      console.log(unique, 'Unique credits Name');

      setCreditorsArtistList(unique)
    }
  }

  const handleCancel = () => {

    document.getElementById('credits-links-content-btn').classList.add('collapsed')


    if ((allCreditsData != 'null') && ((allCreditsData).length > 0)) {

      const finalData = allCreditsData.map(e => {
        return { name: e.name, type: e.type }
      })
      console.log(finalData, 'allCreditsData');
      setcreditsData(finalData)
    } else {
      console.log([creditsTemplate], 'allCreditsData');
    }

  }


  useEffect(() => {
    GetAllCreditorsName()


    if ((allCreditsData != 'null') && ((allCreditsData).length > 0)) {

      const finalData = allCreditsData.map(e => {
        return { name: e.name, type: e.type }
      })
      console.log(finalData, 'allCreditsData');

      setcreditsData(finalData)
    } else {
      console.log([creditsTemplate], 'allCreditsData');
    }

  }, [allCreditsData]);



  return (
    <form onSubmit={(e) => updateCreditsData(e)}>
      <section className="accordion-item d-grid shadow rounded-3">
        <fieldset>
          <legend className="accordion-header accordion-sticky-header sticky-top rounded-3">
            <h3>
              <button id="credits-links-content-btn" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#credits-links-content" aria-expanded="true" aria-controls="credits-links-content" > Credits </button>
            </h3>
          </legend>

          <div id="credits-links-content" class="accordion-collapse collapse" data-bs-parent="#accordionAdminSettings" >
            <div class="accordion-body">
              <div id="Credits" className="d-grid gap-3 px-3">
                <div className="d-flex gap-3 justify-content-end py-3 border-top sticky-top accordion-sticky-actions">
                  <button type="button" onClick={handleCancel} aria-label="Cancel" className="btn btn-secondary" data-bs-toggle="collapse" data-bs-target="#credits-links-content" aria-expanded="true" aria-controls="credits-links-content"> Cancel </button>
                  <button aria-label="Save Credits and Continue" className="btn btn-primary"> Save and Continue</button>
                </div>
                {
                  creditsData.map((creditorsData, i) => {
                    return (
                      <div id="credits-div">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4>Credits {i + 1}</h4>
                          <div>
                            <button type="button" className="icon-btn icon-btn-danger" aria-label={`Delete Lyrics ${i + 1}`} onClick={() => contentDelete(i, creditsData, setcreditsData)}>
                              <span className="icon-delete"></span>
                            </button>
                            <button type="button" id={`lyricsTop${i}`} className={`icon-btn icon-btn-primary`} disabled={i == creditsData.length - creditsData.length ? true : false}
                              aria-label={`Move Up Lyrics ${i + 1}`} onClick={i == creditsData.length - creditsData.length ? () => { return; } : () => contentUp(i, creditsData, setcreditsData, "lyrics")} >
                              <span className="icon-top-arrow"></span>
                            </button>
                            <button type="button" id={`lyricsDown${i}`} className={`icon-btn icon-btn-primary`} disabled={i == creditsData.length - 1 ? true : false} aria-label={`Move Down Lyrics ${i + 1}`} onClick={i == creditsData.length - 1 ? () => { return; } : () => contentDown(i, creditsData, setcreditsData, "lyrics")} >
                              <span className="icon-down-arrow"></span>
                            </button>
                          </div>
                        </div>

                        <div className="d-grid gap-3">
                          <div class="form-floating">
                            <select class="form-select" aria-label={`Select Credits ${i + 1} Type`} id={`creditsType${i + 1}`} name={`creditsType${i + 1}`} value={creditorsData.type} onChange={(e) => creditsTypeValueHandler(e, i)} >
                              <option value="composer" selected> Composer </option>
                              <option value="lyricist"> Lyricist </option>
                              <option value="mastering"> Mastering </option>
                              <option value="mixing">Mixing</option>
                              <option value="music-producer"> Music Producer </option>
                              <option value="vocals">Vocals</option>
                            </select>
                            <label for={`creditsType${i + 1}`}> Type </label>
                          </div>

                          <TypeAheadLoops
                            list={CreditorsArtistList}
                            valueKey={'name'}
                            stateData={creditorsData}
                            updateStateFunction={creditsNameValueHandler}
                            index={i}
                            attrValue={`creditorName${i + 1}`}
                            label={"Creditor's Name"}
                            ariaLabel={`Add Creditor ${i + 1} Name`}
                            suggestionDivId={`suggestionDivCreditor${i + 1}`}
                            valueProp="name"
                            keyProp="name"
                          />

                        </div>
                      </div>
                    );
                  })}

                <div className="">
                  <h4>Add Another (Optional)</h4>
                  <div className="d-grid">
                    <button
                      type="button"
                      onClick={() =>
                        setcreditsData([
                          ...creditsData,
                          {
                            type: "composer",
                            name: "",
                          },
                        ])
                      }
                      aria-label={`Add Another Credits Optional`}
                      className="btn btn-secondary"
                    >
                      Add Another
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </section>
    </form>

  )
}

export default Credits
