import { Helmet } from 'react-helmet-async';
import { createContext, useContext, useEffect, useState } from "react"
import { getSEOData } from '../APIs/api'
import { ARTIST, FILE_URL } from '../../config'
import zintune_favicon from "../../assets/zintune-favicon.png"

// Step 1
const SEOContext = createContext()

// Step 2
export const useSEO = () => {
    return useContext(SEOContext);
}

// Step 3
export const SEOProvider = ({ children }) => {
    const [artist, setArtist] = useState(ARTIST);
    const [SEO_Data, setSEO_Data] = useState({});
    const [OG_Data, setOG_Data] = useState({});


    const fetchingData = async (_artist) => {
        const res = await getSEOData({ username: _artist });
        console.log("fetchingData", res);

        if (res.success) {
            setSEO_Data(res.SEO_Data)
            setOG_Data({ ...res.OG_Data, "og:image": FILE_URL + res.OG_Data["og:image"] })

        } else {
            // alert("SEO ERROR!!!")
        }
    }

    useEffect(() => {
        if (ARTIST != "") fetchingData(ARTIST)
        console.log("current artist", artist);

    }, [artist])



    return (
        <SEOContext.Provider value={{ SEO_Data, setSEO_Data, OG_Data, setOG_Data, artist }}>

            {children}

            <SEO />

        </SEOContext.Provider>
    )
}



function SEO() {
    const { SEO_Data, OG_Data } = useSEO();

    if (SEO_Data == null) return

    return (
        <Helmet>

            {SEO_Data.description && <meta name="description" content={SEO_Data.description} />}
            {SEO_Data.author && <meta name="author" content={SEO_Data.author} />}
            {SEO_Data.title && <title>{SEO_Data.title}</title>}


            { /* Facebook tags */}
            {OG_Data["og:title"] && <meta property="og:title" content={OG_Data["og:title"]} />}
            {OG_Data["og:type"] && <meta property="og:type" content={OG_Data["og:type"]} />}
            {OG_Data["og:music:musician"] && <meta property="og:music:musician" content={OG_Data["og:music:musician"]} />}
            {OG_Data["og:video"] && <meta property="og:video" content={OG_Data["og:video"]} />}
            {OG_Data["og:url"] && <meta property="og:url" content={OG_Data["og:url"]} />}
            {OG_Data["og:image"] && <meta property="og:image" content={OG_Data["og:image"]} />}
            {OG_Data["og:description"] && <meta property="og:description" content={OG_Data["og:description"]} />}
            { /* End Facebook tags */}

            {/* Favicons */}
            {
                SEO_Data?.favicons != null && SEO_Data?.favicons["apple-touch-icon-72x72.png"] != undefined ?
                    <link rel="apple-touch-icon" sizes="76x76" href={`${FILE_URL}/${SEO_Data?.username}/${SEO_Data?.favicons["apple-touch-icon-72x72.png"]}`} />
                    :
                    <link rel="apple-touch-icon" sizes="76x76" href={zintune_favicon} />
            }
            {
                SEO_Data?.favicons != null && SEO_Data?.favicons["favicon-16x16.png"] != undefined ?
                    <link rel="icon" type="image/png" sizes="32x32" href={`${FILE_URL}/${SEO_Data?.username}/${SEO_Data?.favicons["favicon-16x16.png"]}`} />
                    :
                    <link rel="icon" type="image/png" sizes="32x32" href={zintune_favicon} />
            }
            {
                SEO_Data?.favicons != null && SEO_Data?.favicons["favicon-32x32.png"] != undefined ?
                    <link rel="icon" type="image/png" sizes="16x16" href={`${FILE_URL}/${SEO_Data?.username}/${SEO_Data?.favicons["favicon-32x32.png"]}`} />
                    :
                    <link rel="icon" type="image/png" sizes="16x16" href={zintune_favicon} />
            }
            {
                SEO_Data?.favicons != null && SEO_Data?.favicons["favicon-32x32.png"] != undefined ?
                    <link rel="icon" href={`${FILE_URL}/${SEO_Data?.favicons["favicon-32x32.png"]}`} />
                    :
                    <link rel="icon" href={zintune_favicon} />
            }

            {/* {SEO_Data?.favicons != null && SEO_Data?.favicons. <meta name="msapplication-TileColor" content="#da532c">}
            {SEO_Data?.favicons != null && SEO_Data?.favicons. <meta name="theme-color" content="#000000">} */}

        </Helmet>
    )
}