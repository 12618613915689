// import './App.css';
import './scss/custom-variables.scss';
import './scss/custom.scss';
// import './css/bootstrap.min.c 1ss'

import { Navigate, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Project from './pages/Project';
import Song from './pages/Song';
import SignUp from './pages/SignUp';
import ForgotPass from './pages/ForgotPass';
import CompleteProfile from './pages/CompleteProfile';
import SignIn from './pages/SignIn';
import AdminDashboard from './pages/Dashboard Pages/AdminDashboard';
import AdminLayout from './pages/Dashboard Pages/AdminLayout';
import EmailSignUp from './pages/Dashboard Pages/EmailSignUp';
import PromotionalCallToAction from './pages/Dashboard Pages/PromotionalCallToAction';
import PromotionalVideo from './pages/Dashboard Pages/PromotionalVideo';
import AdminEmail from './pages/Dashboard Pages/AdminEmail';
import AdminPassword from './pages/Dashboard Pages/AdminPassword';
import SocialLinks from './pages/Dashboard Pages/SocialLinks';
import AddNew from './pages/Dashboard Pages/AddNew';
import AddSong from './pages/Dashboard Pages/AddSong';

import { useToast } from './ContextAPI/Components/notify';
import { useEffect } from 'react';
import AddAlbum from './pages/Dashboard Pages/AddAlbum';
import EditSong from './pages/Dashboard Pages/EditSong';
import { useAuth } from './ContextAPI/Components/auth';
import axios from 'axios';
import { useLoader } from './ContextAPI/Components/loaderContext';
import Loader from './components/dashboard-components/loader';
import EditAlbum from './pages/Dashboard Pages/EditAlbum';
import { ARTIST } from './config';
import { initFBPixel, initGA, initGTM, logPageView } from './helpers/gtm';
// import ReactGA from "react-ga"


// const trackingID = "G-385BSGY2CF"
// ReactGA.initialize(trackingID)



function App() {
  // const Url = window.location.origin
  const { getLoginUser, user } = useAuth();
  const { loader } = useLoader()
  const { showNotify, cleartoastAlert } = useToast()
  const token = JSON.parse(localStorage.getItem("id")) || null;
  axios.defaults.headers.common['pk2'] = token;
  axios.defaults.headers.common['pathname'] = window.location.pathname;
  // let count = 0;


  alert("hello")
  useEffect(() => {

    getLoginUser();

    


    // Dev
    // let GTM_CONTAINER_ID = "GTM-WXD9MZFQ"
    // let GA_TRACKING_ID = "G-MLS0NBFNWG"
    // let FB_PIXEL_ID = "518851520725595"

    // Client
    let FB_PIXEL_ID = "1210369880067763"
    let GTM_CONTAINER_ID = "GTM-WMJCSCCK"
    let GA_TRACKING_ID = "G-WEQ1QN25GM"

    // ADD CONDITION IF GA ID AND GTM ID EXISTS IN USER DATA THEN INITIALIZE GA AND GTM IN APP
    console.log(`
      FB_PIXEL_ID -> ${FB_PIXEL_ID}
      GTM_CONTAINER_ID -> ${GTM_CONTAINER_ID}
      GA_TRACKING_ID -> ${GA_TRACKING_ID}  
    `);

    if (GTM_CONTAINER_ID && GA_TRACKING_ID) {
      
      initGTM(GTM_CONTAINER_ID)
      initGA(GA_TRACKING_ID)
      logPageView()
    }

    // ADD CONDITION IF FB PIXEL ID EXISTS IN USER DATA THEN INITIALIZE FB PIXEL IN APP
    if (FB_PIXEL_ID) {
      initFBPixel(FB_PIXEL_ID)
    }

    // if (Url !== 'http://localhost:3000') {
    //   console.log = () => { }
    // }
  }, [])

  // useEffect(() => {
  //   return () => {
  //     if (user) {

  //       // if (count == 0) {

  //       //   console.log(user, "useruseruseruseruseruseruseruseruseruser");
  //       //   const script1 = document.createElement('script');
  //       //   script1.type = 'text/javascript';
  //       //   script1.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //       //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //       //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //       //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //       // })(window,document,'script','dataLayer','${user.googleTagManagerId}');(window,document,'script','dataLayer','${user.googleAnalyticsId}');`;
  //       //   document.documentElement.appendChild(script1)


  //       //   const script2 = document.createElement('script');
  //       //   script2.type = 'text/javascript';
  //       //   script2.src = "https://www.googletagmanager.com/gtag/js?id=GTM-WVJVNXMH";
  //       //   document.documentElement.appendChild(script2)

  //       //   const script3 = document.createElement('script');
  //       //   script3.type = 'text/javascript';
  //       //   script3.src = "https://www.googletagmanager.com/gtag/js?id=G-XZY3YKSKV1";
  //       //   document.documentElement.appendChild(script3)


  //       //   const script4 = document.createElement('script');
  //       //   script4.type = 'text/javascript';
  //       //   script4.innerHTML = `window.dataLayer = window.dataLayer || [];
  //       //   function gtag(){dataLayer.push(arguments)};
  //       //   gtag('config', '${user.googleTagManagerId}');
  //       //   gtag('config', '${user.googleAnalyticsId}');`;
  //       //   document.documentElement.appendChild(script4)

  //       //   const script5 = document.createElement('script');
  //       //   script5.type = 'text/javascript';
  //       //   script5.innerHTML = `!function(f,b,e,v,n,t,s)
  //       //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //       //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //       //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //       //   n.queue=[];t=b.createElement(e);t.async=!0;
  //       //   t.src=v;s=b.getElementsByTagName(e)[0];
  //       //   s.parentNode.insertBefore(t,s)}(window, document,'script',
  //       //   'https://connect.facebook.net/en_US/fbevents.js');
  //       //   fbq('init', '${user.metaPixelId}');
  //       //   fbq('track', 'PageView');`;
  //       //   document.documentElement.appendChild(script5)


  //       //   const noscript1 = document.createElement('noscript');
  //       //   const imgTag = document.createElement('img');
  //       //   imgTag.src = "https://www.facebook.com/tr?id=940646907597422&ev=PageView&noscript=1";
  //       //   imgTag.style.display = "none";
  //       //   imgTag.height = "1"
  //       //   imgTag.width = "1"

  //       //   noscript1.appendChild(imgTag)
  //       //   document.documentElement.appendChild(noscript1)


  //       //   const noscript2 = document.createElement('noscript');
  //       //   const iframe = document.createElement('iframe');
  //       //   iframe.src = `https://www.googletagmanager.com/ns.html?id=${user.googleTagManagerId}`;
  //       //   iframe.style.display = "none";
  //       //   iframe.style.visibility = "hidden";
  //       //   iframe.height = "0"
  //       //   iframe.width = "0"

  //       //   noscript2.appendChild(iframe)
  //       //   document.body.appendChild(noscript2)


  //       // }


  //       // count = count + 1

  //     }
  //   }
  // }, [user])

  return (
    <>

      {
        loader && <Loader />
      }


      <div className="toast-container p-5 position-fixed top-0 start-50 translate-middle-x">

        {
          showNotify.msg != null &&
          <div className={`toast show align-items-center ${showNotify.type === "success" ? 'text-bg-success' : 'text-bg-danger'} border-0`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
                {showNotify?.msg}
              </div>
              <button type="button" className="btn-close btn-close-white p-4 me-2 m-auto" onClick={() => cleartoastAlert()} data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
        }

      </div>

      <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route exact path='/project/:id' element={<Project />} />
        <Route exact path='/song/:id' element={<Song />} />

        <Route exact path='/sign-in' element={<SignIn />} />
        <Route exact path='/sign-up' element={<SignUp />} />
        <Route exact path='/forgot-password' element={<ForgotPass />} />
        <Route exact path='/complete-profile/:id' element={<CompleteProfile />} />

        {
          token != null &&
          <>
            <Route exact path='/admin/dashboard' element={<AdminDashboard />} />
            <Route exact path='/admin/email' element={<AdminEmail />} />
            <Route exact path='/admin/password' element={<AdminPassword />} />
            <Route exact path='/admin/layout' element={<AdminLayout />} />
            <Route exact path='/admin/social-links' element={<SocialLinks />} />
            <Route exact path='/admin/email-sign-up' element={<EmailSignUp />} />
            <Route exact path='/admin/promotional-call-to-action' element={<PromotionalCallToAction />} />
            <Route exact path='/admin/promotional-video' element={<PromotionalVideo />} />
            <Route exact path='/admin/add-new' element={<AddNew />} />
            <Route exact path='/admin/add-new-song' element={<AddSong />} />
            <Route exact path='/admin/add-new-album' element={<AddAlbum />} />
            <Route exact path='/admin/edit-song/:songID' element={<EditSong />} />
            <Route exact path='/admin/edit-album/:albumID' element={<EditAlbum />} />
          </>
        }

        <Route path='*' element={<Navigate to={`/?user=${ARTIST}`} replace={true} />} />

      </Routes>
    </>
  );
}


export default App;