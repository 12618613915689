import React, { useEffect, useMemo, useState } from 'react'

import { Link, NavLink, useLocation } from 'react-router-dom'
import { GetAllSongs, getAllAlbum } from '../../ContextAPI/APIs/api'
import { useLoader } from '../../ContextAPI/Components/loaderContext'
import { useSidebar } from '../../ContextAPI/Components/sidebar'
import { scrollToElement } from '../../helpers/util'

const Sidebar = () => {
    const location = useLocation();
    const { songs, setsongs, Albums, setAlbums, getSongsAndAlbums } = useSidebar()

    const [AccordionState, setAccordionState] = useState(false)
    const [gotAccordion, setgotAccordion] = useState(false)

    const pathname = location.pathname.split('/')[2]
    const mySlug = location.pathname.split('/')[3]

    useMemo(() => {
        getSongsAndAlbums()

    }, [])

    const handleSongClick = (id) => scrollToElement("releases-songs-content", id, "Song element not found!");


    const handleAlbumClick = (id) => scrollToElement("releases-albums-content", id, "Album element not found!");


    const handleOtherItemsClick = (divId, id) => scrollToElement(divId, id, "element not found!");

    useEffect(() => {


        if (pathname == 'edit-song') {
            if (document.getElementById('releases-songs-content') == null) {
                setTimeout(() => {
                    setgotAccordion(true)
                }, 1000);

                return
            }
            handleSongClick(mySlug)
        }

        else if (pathname == 'edit-album') {
            if (document.getElementById('releases-albums-content') == null) {
                setTimeout(() => {
                    setgotAccordion(true)
                }, 1000);

                return
            }
            handleAlbumClick(mySlug)
        }

        else {
            console.log(pathname, 'mainPathBhaiMainPath');
            if (pathname == 'dashboard' || pathname == 'email' || pathname == 'password') {
                handleOtherItemsClick('profile-content', pathname)
            } else if (pathname == 'layout' || pathname == 'social-links' || pathname == 'email-sign-up' || pathname == 'promotional-call-to-action' || pathname == 'promotional-video') {
                handleOtherItemsClick('home-page-content', pathname)
            } else if (pathname == 'add-new') {
                handleOtherItemsClick('for-add-new', pathname)
            }

        }

        setAccordionState(window.localStorage.getItem('accordionState'))

        console.log(window.localStorage.getItem('accordionState'), 'lallu');

    }, [gotAccordion])


    return <>
        <div className="side-menu sticky-lg-top w-100 scroll-fade bg-dark" data-bs-theme="dark">
            {/* <!-- Close menu with fade --> */}
            <div className="d-lg-none mb-3 d-grid">
                <button className="btn btn-outline-light toggle-menu">
                    Close Song Menu
                </button>
            </div>
            <div className="side-scrolling pt-lg-3" id='dashboard-sidebar'>
                <div className="accordion accordion-sidebar d-grid gap-3" id="accordionAdminSidebar">
                    <div className="accordion-item" id='profile'>
                        <h2 className="accordion-header h3 side-menu-sticky-header border-bottom">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#profile-content" aria-expanded="true" aria-controls="profile-content">
                                Profile
                            </button>
                        </h2>
                        <div id="profile-content" className="accordion-collapse collapse show">
                            <div className="accordion-body">
                                <ul className='list-group'>
                                    <li><NavLink activeClassName='active' id='dashboard' onClick={() => handleOtherItemsClick('profile-content', 'dashboard')} className='list-group-item' to={'/admin/dashboard'} aria-current="true">General</NavLink></li>
                                    <li><NavLink activeClassName='active' id='email' onClick={() => handleOtherItemsClick('profile-content', 'email')} className='list-group-item' to={'/admin/email'}>Email</NavLink></li>
                                    <li><NavLink activeClassName='active' id='password' onClick={() => handleOtherItemsClick('profile-content', 'password')} className='list-group-item' to={'/admin/password'}>Password</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" id='homepage'>
                        <h2 className="accordion-header h3 side-menu-sticky-header border-bottom">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#home-page-content" aria-expanded="true" aria-controls="home-page-content">
                                Home Page
                            </button>
                        </h2>
                        <div id="home-page-content" className="accordion-collapse collapse show">
                            <div className="accordion-body">
                                <ul className='list-group'>
                                    <li><NavLink activeClassName='active' id='layout' onClick={() => handleOtherItemsClick('home-page-content', 'layout')} className='list-group-item' to={'/admin/layout'}>Layout</NavLink></li>
                                    <li><NavLink activeClassName='active' id='social-links' onClick={() => handleOtherItemsClick('home-page-content', 'social-links')} className='list-group-item' to={'/admin/social-links'}>Social Links</NavLink></li>
                                    <li><NavLink activeClassName='active' id='email-sign-up' onClick={() => handleOtherItemsClick('home-page-content', 'email-sign-up')} className='list-group-item' to={'/admin/email-sign-up'}>Email Sign Up</NavLink></li>
                                    <li><NavLink activeClassName='active' id='promotional-call-to-action' onClick={() => handleOtherItemsClick('home-page-content', 'promotional-call-to-action')} className='list-group-item' to={'/admin/promotional-call-to-action'}>Promotional Call to Action</NavLink></li>
                                    <li><NavLink activeClassName='active' id='promotional-video' onClick={() => handleOtherItemsClick('home-page-content', 'promotional-video')} className='list-group-item' to={'/admin/promotional-video'}>Promotional Video</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" id='releases'>
                        <div id='for-add-new' className='releases-menu-sticky-header'>
                            <h2 className="accordion-header h3">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#releases-content" aria-expanded="true" aria-controls="releases-content">
                                    Releases
                                </button>
                            </h2>
                            <div className='d-grid'>
                                <NavLink activeClassName='active' id='add-new' onClick={() => handleOtherItemsClick('for-add-new', 'add-new')} className='btn btn-light' to={'/admin/add-new'}>Add New Song or Album</NavLink>
                            </div>
                        </div>

                        <div id="releases-content" className="accordion-collapse collapse show">
                            <div className="accordion-body ps-3">
                                <div className='accordion d-grid gap-3' id="releases-content-group">
                                    {
                                        songs.length > 0 && <>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header releases-submenu-sticky-header border-bottom">
                                                    <button className={`accordion-button ${AccordionState == 'songs' ? '' : 'collapsed'}`} onClick={AccordionState == 'songs' ? () => { setAccordionState(false); window.localStorage.setItem('accordionState', 'false') } : () => { setAccordionState('songs'); window.localStorage.setItem('accordionState', 'songs') }} type="button" data-bs-toggle="collapse" data-bs-target="#releases-songs-content" aria-expanded={AccordionState == 'songs' ? true : false} aria-controls="releases-songs-content">
                                                        {/* <button className={`accordion-button`} onClick={AccordionState == 'songs' ? () => { setAccordionState(false); window.localStorage.setItem('accordionState', 'false') } : () => { setAccordionState('songs'); window.localStorage.setItem('accordionState', 'songs') }} type="button" data-bs-toggle="collapse" data-bs-target="#releases-songs-content" aria-expanded={true} aria-controls="releases-songs-content"> */}
                                                        Songs
                                                    </button>
                                                </h4>

                                                <div id="releases-songs-content" className={`accordion-collapse collapse  ${AccordionState == 'songs' ? 'show' : ''}`} data-bs-parent="#releases-content-group">
                                                    {/* <div id="releases-songs-content" className={`accordion-collapse collapse show`} data-bs-parent="#releases-content-group"> */}
                                                    <div class="accordion-body">
                                                        <ul className='list-group'>
                                                            {songs.map((e, i) => {
                                                                return <li key={i}><NavLink activeClassName='active' id={e.slug} className='list-group-item' to={`/admin/edit-song/${e.slug}`} onClick={() => handleSongClick(e.slug)}>{e.title}</NavLink></li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        Albums.length > 0 && <>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header releases-submenu-sticky-header border-bottom">
                                                    <button className={`accordion-button ${AccordionState == 'albums' ? '' : 'collapsed'}`} onClick={AccordionState == 'albums' ? () => { setAccordionState(false); window.localStorage.setItem('accordionState', 'false') } : () => { setAccordionState('albums'); window.localStorage.setItem('accordionState', 'albums') }} type="button" data-bs-toggle="collapse" data-bs-target="#releases-albums-content" aria-expanded={AccordionState == 'albums' ? true : false} aria-controls="releases-albums-content">
                                                        Albums / Projects
                                                    </button>
                                                </h4>
                                                <div id="releases-albums-content" className={`accordion-collapse collapse ${AccordionState == 'albums' ? 'show' : ''}`} data-bs-parent="#releases-content-group">
                                                    <div class="accordion-body">
                                                        <ul className='list-group'>
                                                            {Albums.map((e, i) => {
                                                                return <li key={i}><NavLink activeClassName='active' className='list-group-item' id={e.slug} to={`/admin/edit-album/${e.slug}`} onClick={() => handleAlbumClick(e.slug)}>{e.title}</NavLink></li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    </>
}


export default Sidebar