import AddSong from './AddSong'


const EditSong = () => {

    return <>

    <AddSong />
       
    </>
}


export default EditSong