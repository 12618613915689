const searchParams = new URLSearchParams(window.location.search);
const ARTIST = searchParams.has('user') ? searchParams.get('user') : ""


/**
 * Here we are checking is 2nd index pathname is equal to admin? 
 * if YES, means we are at dashboard
 * if NO, means we are at visitor page and then 
 *  => if there is no artist name in url then redirect to default artist
 */ 
const defaultCreator = "yarleygmusic";
if (ARTIST == "" && (window.location.pathname).split("/")[1] != "admin" && (window.location.pathname).split("/")[1] != "sign-in" && (window.location.pathname).split("/")[1] != "sign-up") window.location.href = `/?user=${defaultCreator}`


const API_URL = `https://api.zintunes.com`;
const FILE_URL = `https://api.zintunes.com/assets/files/${ARTIST}`;


// const API_URL = `http://localhost:5000`;
// const FILE_URL = `http://localhost:5000/assets/files/${ARTIST}`;


export {
    API_URL,
    FILE_URL,
    ARTIST,
    defaultCreator
}