import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import YARLEY from "../assets/yarleyg.png"

import YOUTUBE from "../assets/svg/youtube.svg"
import SPOTIFY from "../assets/svg/spotify.svg"
import ITUNES from "../assets/svg/itunes.svg"
import TIDAL from "../assets/svg/tidal.svg"
import AMAZON_MUSIC from "../assets/svg/amazon-music.svg"
import SOUND_CLOUD from "../assets/svg/soundcloud.svg"
import APPLE_PODCAST from "../assets/svg/apple-podcast.svg"
import { getAlbumDetail } from '../ContextAPI/APIs/api'
import { FILE_URL, ARTIST } from '../config'
import Loader from '../components/dashboard-components/loader'
import VideoModal from '../components/VideoModal'
import { createUrlFront } from '../helpers/createUrlFront'


function Project() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [album, setAlbum] = useState(null)
    const [songs, setSongs] = useState([])
    const [showMobMenu, setShowMobMenu] = useState(false)

    
    async function getAlbum() {
        const res = await getAlbumDetail(id, ARTIST)
        console.log(res, 'abcefg');


        if (res.success && (res.message).length > 0) {
            const data = (res.message).length > 0 ? res.message[0] : null
            setAlbum(data)

            console.log("videos", ((res?.videos).length > 0 ? res?.videos : null));

            if ((data.songs).length > 0 && (res?.videos).length > 0) {
                let songsList = data.songs;
                let videosList = res?.videos;

                (data.songs).forEach((song, i) => {

                    const theseVideos = videosList.filter(video => {
                        if (video.song == song._id) return video
                    });

                    songsList[i].videos = theseVideos;
                });

                setSongs(songsList)
                console.log("songsList", songsList);

            }
        } else {
            navigate('/')
        }
    }


    useEffect(() => {
        window.scrollTo({ top: 0, behaviour: "unset" });

        if (ARTIST == "") return navigate('/')
        getAlbum()

    }, [])

    

    if (album == null) {
        return <Loader />
    }



    return (
        <>
            <div className="skippy visually-hidden-focusable overflow-hidden">
                <div className="container">
                    <a className="d-inline-flex p-2 m-1" href="#releases">Skip to releases</a>
                </div>
            </div>

            <main>

                {/* <!-- Back Btn --> */}
                <div className="bg-dark p-3 py-lg-4 text-center sticky-top back-btn" data-aos="fade-down" data-aos-offset="20" data-aos-delay="100">
                    <button className="btn btn-outline-light song-menu" onClick={() => navigate(-1)}>Go back</button>
                </div>

                {/* <!-- Project Icon --> */}
                <section>
                    <div className="py-md-5 py-4 bg-white text-black text-center">
                        <div className="container">
                            <div className="row">
                                <div className="d-grid gap-5">
                                    <h1 className="" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">{album.title}</h1>
                                    <div className="music-icon position-relative mx-auto z-0">
                                        <img src={`${FILE_URL}/${album.img}`} alt="" data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = YARLEY;
                                            }}
                                        />
                                    </div>
                                    <ul data-aos="zoom-in-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20"
                                        className="d-flex flex-wrap gap-3 list-unstyled mx-auto justify-content-center social-group shadow p-2 rounded">
                                        <span className="visually-hidden">{album.title}</span>
                                        {(album.youtube != "" && album.youtube != null) && <li><a className="btn-square" href={album.youtube} target='_blank'><img src={YOUTUBE} alt="Yarley G's Youtube" /></a></li>}
                                        {(album.spotify != "" && album.spotify != null) && <li><a className="btn-square" href={album.spotify} target='_blank'><img src={SPOTIFY} alt="Yarley G's Spotify" /></a></li>}
                                        {(album.appleMusic != "" && album.appleMusic != null) && <li><a className="btn-square" href={album.appleMusic} target='_blank'><img src={ITUNES} alt="Yarley G's Apple Music" /></a></li>}
                                        {(album.tidal != "" && album.tidal != null) && <li><a className="btn-square" href={album.tidal} target='_blank'><img src={TIDAL} alt="Yarley G's Tidal" /></a></li>}
                                        {(album.amazonMusic != "" && album.amazonMusic != null) && <li><a className="btn-square" href={album.amazonMusic} target='_blank'><img src={AMAZON_MUSIC} alt="Yarley G's Amazon Music" /></a></li>}
                                        {(album.soundCloud != "" && album.soundCloud != null) && <li><a className="btn-square" href={album.soundCloud} target='_blank'><img src={SOUND_CLOUD} alt="Yarley G's Soundcloud" /></a></li>}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Releases --> */}
                <section className="releases">
                    <div className="bg-dark text-white back-btn-space" data-bs-theme="dark">
                        {/* <!-- Open menu with fade --> */}
                        <div className="sticky-top bg-dark">
                            <div className="container">
                                <div className="d-lg-none pt-3 pb-3 bg-black">
                                    <a className="btn bg-black btn-outline-light w-100 toggle-menu" onClick={() => setShowMobMenu(true)}>
                                        Open Song Menu
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3">
                                    <div className={`side-menu sticky-lg-top w-100 scroll-fade top-lg-6rem ${showMobMenu ? "show" : ""}`}>
                                        {/* <!-- Close menu with fade --> */}
                                        <div className="d-lg-none mb-4">
                                            <a className="btn bg-black btn-outline-light w-100 toggle-menu" onClick={() => setShowMobMenu(false)}>
                                                Close Song Menu
                                            </a>
                                        </div>
                                        <div className="side-scrolling pt-lg-4rem skip-back-btn">
                                            <div className="accordion accordion-sidebar d-grid gap-lg-5 gap-4 px-3 pt-1 pb-5">

                                                {
                                                    ((album.songs).filter(e => e.type == "lyrical")).length > 0 &&
                                                    <div className="accordion-item">
                                                        <h3 className="accordion-header">
                                                            <button className="accordion-button lh-1" type="button" data-bs-toggle="collapse" data-bs-target="#accordion1" aria-expanded="true" aria-controls="accordion1">
                                                                Lyrical
                                                            </button>
                                                        </h3>
                                                        <div id="accordion1" className="accordion-collapse collapse show">
                                                            <div className="accordion-body py-3">
                                                                <ol className="list-group-numbered p-0 mb-0" id="stream-list">
                                                                    {
                                                                        (album.songs).length > 0 && (album.songs).map((song, i) => {
                                                                            if (song.type != "instrumental") {
                                                                                return <React.Fragment key={song.title}>
                                                                                    <span className="visually-hidden">{album.title}</span>
                                                                                    <li>
                                                                                        <a className="list-group-item" href={`#${song.title}`}>{song.title}</a>
                                                                                    </li>
                                                                                </ React.Fragment>
                                                                            }
                                                                        })
                                                                    }
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    ((album.songs).filter(e => e.type == "instrumental")).length > 0 &&
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button lh-1" type="button" data-bs-toggle="collapse" data-bs-target="#accordion2" aria-expanded="false" aria-controls="accordion2">
                                                                Instrumental
                                                            </button>
                                                        </h2>
                                                        <div id="accordion2" className="accordion-collapse collapse show">
                                                            <div className="accordion-body py-3">
                                                                <ol className="list-group-numbered p-0 mb-0">
                                                                    {
                                                                        (album.songs).length > 0 && (album.songs).map((song, i) => {
                                                                            if (song.type == "instrumental") {
                                                                                return <React.Fragment key={i}>
                                                                                    <span className="visually-hidden">Vivid Echoes songs</span>
                                                                                    <li>
                                                                                        <a className="list-group-item" href={`#${song.title}`}>{song.title}</a>
                                                                                    </li>
                                                                                </ React.Fragment>
                                                                            }
                                                                        })
                                                                    }
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* SONGS AREA */}
                                {
                                    songs.length > 0 &&
                                    <div className="col-lg text-center scroll-fade skip-back-btn">
                                        <div className="d-grid gap-5 py-5">

                                            {
                                                songs?.map((song, i) => {
                                                    console.log(song, 'songsongsong');
                                                    return <article key={song.title} id={song.title} className="d-grid gap-3 pt-7 mt-n7 pt-lg-9 mt-lg-n3 mt-lg-n3">
                                                        <div className="d-grid gap-3">
                                                            <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                                                <h4 className="h1">{song.title} </h4>
                                                                <div className='fw-normal h3' style={{ textTransform: "capitalize" }}>
                                                                    {
                                                                        typeof song.artists !== "undefined" && song.artists.length > 0 &&
                                                                        (song.artists).filter(artist => artist?.type === 'primary').map((artist, i) => {
                                                                            // if (artist?.type === "primary") {
                                                                            return (
                                                                                <React.Fragment key={i}>
                                                                                    {artist.name}
                                                                                    {
                                                                                        ((song.artists).filter(artist => artist?.type === 'primary')).length > 0 &&
                                                                                        <>
                                                                                            {
                                                                                                i == ((song.artists).filter(artist => artist?.type === 'primary')).length - 1 ? "" : ", "
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </React.Fragment>
                                                                            );
                                                                            // }
                                                                            return null;
                                                                        })
                                                                    }
                                                                    <br />
                                                                    {
                                                                        typeof song.artists !== "undefined" && song.artists.length > 0 &&
                                                                        (song.artists).filter(artist => artist?.type === 'featured-artist').map((artist, i) => {
                                                                            return (
                                                                                <>
                                                                                    {' '}
                                                                                    <React.Fragment key={i}>
                                                                                        {
                                                                                            ((song.artists).filter(artist => artist?.type === 'featured-artist')).length > 0 &&
                                                                                            <>
                                                                                                {
                                                                                                    i == 0 ? "featuring " : ""
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {artist.name}
                                                                                        {
                                                                                            ((song.artists).filter(artist => artist?.type === 'featured-artist')).length > 0 &&
                                                                                            <>
                                                                                                {
                                                                                                    i == ((song.artists).filter(artist => artist?.type === 'featured-artist')).length - 1 ? "" : ", "
                                                                                                }
                                                                                            </>
                                                                                        }

                                                                                    </React.Fragment>
                                                                                </>
                                                                            );
                                                                            return null;
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <a href={`/song/${song.slug}${createUrlFront({ type: song?.creator || "" })}`} className="btn btn-outline-light">View Credits, Lyrics and more</a>
                                                            </div>
                                                        </div>

                                                        {/* STREAMING LINKS */}
                                                        {
                                                            ((song.youtube != "" && song.youtube != null) ||
                                                                (song.spotify != "" && song.spotify != null) ||
                                                                (song.appleMusic != "" && song.appleMusic != null) ||
                                                                (song.tidal != "" && song.tidal != null) ||
                                                                (song.amazonMusic != "" && song.amazonMusic != null) ||
                                                                (song.soundCloud != "" && song.soundCloud != null)) &&
                                                            <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                                                <div className="d-grid gap-3">
                                                                    <h5 className="h3">Stream on</h5>
                                                                </div>
                                                                <div className="row g-3">
                                                                    {
                                                                        (song.youtube != "" && song.youtube != null) &&
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <a className="btn btn-play" href={song.youtube}>
                                                                                <img width="48" src={YOUTUBE} alt="youtube" />
                                                                                Youtube
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (song.spotify != "" && song.spotify != null) &&
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <a className="btn btn-play" href={song.spotify}>
                                                                                <img width="48" src={SPOTIFY} alt="spotify" />
                                                                                Spotify
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (song.appleMusic != "" && song.appleMusic != null) &&
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <a className="btn btn-play" href={song.appleMusic}>
                                                                                <img width="48" src={ITUNES} alt="appleMusic" />
                                                                                Apple Music
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (song.tidal != "" && song.tidal != null) &&
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <a className="btn btn-play" href={song.tidal}>
                                                                                <img width="48" src={TIDAL} alt="tidal" />
                                                                                Tidal
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (song.amazonMusic != "" && song.amazonMusic != null) &&
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <a className="btn btn-play" href={song.amazonMusic}>
                                                                                <img width="48" src={AMAZON_MUSIC} alt="amazonMusic" />
                                                                                Amazon Music
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (song.soundCloud != "" && song.soundCloud != null) &&
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <a className="btn btn-play" href={song.soundCloud}>
                                                                                <img width="48" src={SOUND_CLOUD} alt="soundCloud" />
                                                                                Soundcloud
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="row g-3">
                                                            {
                                                                (typeof song?.videos != "undefined") && (song?.videos).length > 0 && (song?.videos).map((video, i) => {
                                                                    return <div key={i} className="col-md-6 flex-grow-1">
                                                                        <div className="video-item d-grid gap-3" data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20" style={{ backgroundImage: `url(${FILE_URL}/${video?.img})` }}>
                                                                            <div className="video-detail">
                                                                                <div>
                                                                                    <VideoModal videoId={video.videoYoutube} videoTitle={video.videoType || 'youtube video'} />

                                                                                    {/* <a className="btn" href={video.videoYoutube}>
                                                                                        <img width="120" alt="" src={YOUTUBE} />
                                                                                    </a> */}
                                                                                </div>
                                                                                <div className="video-description d-grid gap-3">
                                                                                    <h4 className="h2">{video.videoType}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        {
                                                                            ((
                                                                                (video?.podcastYoutube != "" && video?.podcastYoutube != null && typeof video?.podcastYoutube != "undefined") ||
                                                                                (video?.podcastSpotify != "" && video?.podcastSpotify != null && typeof video?.podcastSpotify != "undefined") ||
                                                                                (video?.podcastItunes != "" && video?.podcastItunes != null && typeof video?.podcastItunes != "undefined") ||
                                                                                (video?.podcastAmazonMusic != "" && video?.podcastAmazonMusic != null && typeof video?.podcastAmazonMusic != "undefined")
                                                                            )
                                                                                && (video.podcastToggle == true))
                                                                            &&
                                                                            <div className='mt-3' data-aos="fade-in" data-aos-easing="ease" data-aos-delay="100" data-aos-offset="20">
                                                                                <ul className="d-flex gap-3 w-100 list-unstyled mx-auto justify-content-center social-group shadow p-2 rounded">
                                                                                    <span className="visually-hidden">I Love You Lord 'Breaking Down The Beat' Videos on other platforms</span>
                                                                                    {
                                                                                        (video?.podcastYoutube != "" && video?.podcastYoutube != null && typeof video?.podcastYoutube != "undefined") &&
                                                                                        <li>
                                                                                            <a className="btn-square" href={video?.podcastYoutube}>
                                                                                                <img src={YOUTUBE} alt={"Podcast Youtube"} />
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    {
                                                                                        (video?.podcastSpotify != "" && video?.podcastSpotify != null && typeof video?.podcastSpotify != "undefined") &&
                                                                                        <li>
                                                                                            <a className="btn-square" href={video?.podcastSpotify}>
                                                                                                <img src={SPOTIFY} alt={"Podcast Spotify"} />
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    {
                                                                                        (video?.podcastItunes != "" && video?.podcastItunes != null && typeof video?.podcastItunes != "undefined") &&
                                                                                        <li>
                                                                                            <a className="btn-square" href={video?.podcastItunes}>
                                                                                                <img src={APPLE_PODCAST} alt={"Podcast Itunes"} />
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    {
                                                                                        (video?.podcastAmazonMusic != "" && video?.podcastAmazonMusic != null && typeof video?.podcastAmazonMusic != "undefined") &&
                                                                                        <li>
                                                                                            <a className="btn-square" href={video?.podcastAmazonMusic}>
                                                                                                <img src={AMAZON_MUSIC} alt={"Podcast AmazonMusic"} />
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                })
                                                            }
                                                        </div>

                                                    </article>
                                                })
                                            }

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </section>

            </main>
        </>
    )
}

export default Project
